input,
select,
textarea
{
    display: block;

    width: 100%;

    resize: none;

    border: none;
    border-radius: inherit;
    outline: none;
    background-color: transparent;
}

button
{
    cursor: pointer;

    border: 0 none;
    background-color: transparent;
}

:root
{
    --indent-base: var(--indent-sm);
    --radius-base: var(--radius-md);
    --icon-size-base: var(--icon-size-md);
    --indent-xs: 8px;
    --radius-xs: 2px;
    --control-height-xs: 28px;
    --indent-sm: 12px;
    --radius-sm: 4px;
    --icon-size-sm: 16px;
    --control-height-sm: 32px;
    --indent-md: 16px;
    --radius-md: 6px;
    --icon-size-md: 24px;
    --indent-lg: 24px;
    --radius-lg: 8px;
    --icon-size-lg: 32px;
    --indent-xl: 32px;
    --radius-xl: 12px;
    --icon-size-xl: 48px;
    --clr-placeholder-type-1: hsl(205, 12%, 82%);
    --clr-placeholder-type-2: hsl(205, 12%, 66%);
    --clr-placeholder-disabled: var(--clr-placeholder-type-1);
}

:root
{
    --duration-sm: .24s;
}

.spinner_LWk7
{
    animation: spinner_GWy6 1.2s linear infinite, spinner_BNNO 1.2s linear infinite;
}

.spinner_yOMU
{
    animation: spinner_GWy6 1.2s linear infinite, spinner_pVqn 1.2s linear infinite;
    animation-delay: .15s;
}

.spinner_KS4S
{
    animation: spinner_GWy6 1.2s linear infinite, spinner_6uKB 1.2s linear infinite;
    animation-delay: .3s;
}

.spinner_zVee
{
    animation: spinner_GWy6 1.2s linear infinite, spinner_Qw4x 1.2s linear infinite;
    animation-delay: .45s;
}

@keyframes spinner_GWy6
{
    0%,
    50%
    {
        width: 9px;
        height: 9px;
    }

    10%
    {
        width: 11px;
        height: 11px;
    }
}

@keyframes spinner_BNNO
{
    0%,
    50%
    {
        x: 1.5px;
        y: 1.5px;
    }

    10%
    {
        x: .5px;
        y: .5px;
    }
}

@keyframes spinner_pVqn
{
    0%,
    50%
    {
        x: 13.5px;
        y: 1.5px;
    }

    10%
    {
        x: 12.5px;
        y: .5px;
    }
}

@keyframes spinner_6uKB
{
    0%,
    50%
    {
        x: 13.5px;
        y: 13.5px;
    }

    10%
    {
        x: 12.5px;
        y: 12.5px;
    }
}

@keyframes spinner_Qw4x
{
    0%,
    50%
    {
        x: 1.5px;
        y: 13.5px;
    }

    10%
    {
        x: .5px;
        y: 12.5px;
    }
}

:root
{
    --font-family-base: 'Noto Sans', sans-serif;
    --font-size-xs: .75rem;
    --font-size-sm: .875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.125rem;
    --font-size-xl: 1.25rem;
    --font-size-2xl: 1.5rem;
    --font-size-block-title: 1.75rem;
    --font-size-widget-title: 2rem;
    --font-size-modal-title: 2rem;
    --font-size-page-title: 2.5rem;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
}

:root
{
    --gap-2xs: 4px;
    --gap-xs: 8px;
    --gap-sm: 12px;
    --gap-md: 16px;
    --gap-lg: 20px;
    --gap-xl: 24px;
    --gap-2xl: 32px;
    --gap-3xl: 40px;
    --gap-4xl: 48px;
    --control-height-sm: 32px;
    --control-height-md: 40px;
    --control-height-lg: 48px;
    --control-height-xl: 56px;
    --radius-sm: 4px;
    --radius-md: 6px;
    --radius-lg: 8px;
    --radius-xl: 12px;
    --radius-2xl: 24px;
    --icon-sm: 16px;
    --icon-md: 24px;
    --icon-lg: 32px;
    --icon-xl: 48px;
    --icon-2xl: 64px;
    --icon-3xl: 80px;
    --photo-xs: 32px;
    --photo-sm: 48px;
    --photo-md: 64px;
    --photo-lg: 88px;
}

:root
{
    --clr-white: hsl(0, 0%, 100%);
    --clr-blue-h: 214;
    --clr-blue-s: 100%;
    --clr-blue-l: 50%;
    --clr-primary-h: var(--clr-blue-h);
    --clr-primary-s: var(--clr-blue-s);
    --clr-primary-l: var(--clr-blue-l);
    --clr-primary: hsl(var(--clr-primary-h), var(--clr-primary-s), var(--clr-primary-l));
    --clr-primary-light: hsl(var(--clr-primary-h), var(--clr-primary-s), 96%);
    --bg-input-clr-light: #f4f6f8;
}

@keyframes blink
{
    0%
    {
        opacity: 0;
    }

    50%
    {
        opacity: 1;
    }

    100%
    {
        opacity: 0;
    }
}

:root
{
    --dark-bg-clr: #22272f;
    --dark-bg-popup-clr: #2a313c;
    --dark-text-clr: #ced7e1;
    --dark-bg-tswitch-clr: #313842;
    --dark-bg-input-clr: #2b384b;
    --dark-bg-input-focus-clr: #38465b;
    --dark-bg-input-error-clr: #2f2d3b;
    --dark-bg-select-box-hover: #313f52;
    --dark-bg-s-link-active-clr: var(--dark-bg-input-clr);
    --dark-border-page-clr: #2a2f35;
    --dark-placeholder-clr: #3a5573;
    --dark-bg-caret-switch-clr: #464f5c;
    --dark-bg-rail-switch-active-clr: var(--dark-bg-input-clr);
    --dark-gray-clr: #4b5a6c;
    --dark-gray-light-clr: #394352;
    --dark-gray-hover-clr: #5b6573;
}

*,
*::before,
*::after
{
    box-sizing: border-box;
}

body
{
    font-family: var(--font-family-base);
    font-size: var(--font-size-md);
    line-height: 1.35;

    overflow: hidden;

    min-width: 960px;
    margin: 0;

    --black-clr: #000;
    --white-clr: #fff;
    --gray-clr: #a8b6bf;
    --gray-light-clr: #e4e6e9;
    --gray-hover-clr: #92a2ac;
    --blue-clr-src: 214deg 100% 50%;
    --blue-clr: hsl(var(--blue-clr-src));
    --blue-hover-clr: #0062e7;
    --blue-active-clr: #0054c6;
    --blue-light-clr: #a8cdff;
    --blue-bg-light-clr: #f9fbff;
    --blue-bg-light-active-clr: #dfedff;
    --blue-bg-answer: #edf5ff;
    --blue-border-answer: #61a4ff;
    --orange-clr: #ff7a00;
    --urgent-clr: #ff370b;
    --error-clr: #eb5e6f;
    --success-clr: #16bd07;
    --success-hover-clr: #0fb900;
    --main-clr: var(--blue-clr);
    --main-hover-clr: var(--blue-hover-clr);
    --bg-clr: var(--white-clr);
    --bg-popup-clr: var(--white-clr);
    --text-clr: #3c414d;
    --bg-tswitch-clr: #c0cdd6;
    --bg-input-clr: var(--bg-input-clr-light);
    --bg-input-focus-clr: #f0f2f4;
    --bg-input-error-clr: #f9f2f4;
    --bg-select-box-clr: var(--white-clr);
    --bg-select-box-hover-clr: #f8f9fb;
    --bg-s-link-active-clr: #f6f7fc;
    --border-page-clr: #f6f6f6;
    --border-file-preview: #e4e6f0;
    --placeholder-clr: #8f9ba2;
    --label-clr: #7e878e;
    --cross-small-clr: #ba5d68;
    --bg-switch-clr: var(--white-clr);
    --bg-rail-switch-active-clr: var(--blue-bg-light-active-clr);
    --bg-border-switch-clr: #d0d0d0;
    --bg-caret-switch-clr: #6e7c86;
    --bg-caret-switch-active-clr: var(--main-clr);
    --bg-table-row-hover: #fdfdfd;
    --bg-table-icon: #eaf1f5;
    --bg-table-icon-urgent: #ff2b2b;
    --bg-tag-clr: var(--white-clr);
    --bg-preloader-page-clr: hsla(0 0% 100% / .80);
    --tab-disabled-clr: #97c3ff;
    --drop-zone-clr: var(--blue-clr);
}

body.theme-dark
{
    --blue-disabled-clr: #97c3ff;
    --blue-bg-answer: #253c5d;
    --bg-clr: var(--dark-bg-clr);
    --bg-popup-clr: var(--dark-bg-popup-clr);
    --text-clr: var(--dark-text-clr);
    --bg-tswitch-clr: var(--dark-bg-tswitch-clr);
    --bg-input-clr: var(--dark-bg-input-clr);
    --bg-input-focus-clr: var(--dark-bg-input-focus-clr);
    --bg-input-error-clr: var(--dark-bg-input-error-clr);
    --bg-select-box-clr: var(--dark-bg-input-focus-clr);
    --bg-select-box-hover-clr: var(--dark-bg-select-box-hover);
    --bg-s-link-active-clr: var(--dark-bg-s-link-active-clr);
    --border-page-clr: var(--dark-border-page-clr);
    --border-file-preview: #404751;
    --placeholder-clr: var(--dark-placeholder-clr);
    --label-clr: var(--dark-placeholder-clr);
    --bg-border-switch-clr: var(--dark-bg-caret-switch-clr);
    --bg-rail-switch-active-clr: var(--dark-bg-rail-switch-active-clr);
    --bg-switch-clr: var(--dark-bg-input-clr);
    --bg-caret-switch-clr: var(--dark-bg-caret-switch-clr);
    --bg-table-row-hover: var(--dark-bg-input-clr);
    --bg-table-icon: var(--dark-bg-input-clr);
    --bg-tag-clr: var(--dark-bg-input-focus-clr);
    --gray-clr: var(--dark-gray-clr);
    --gray-light-clr: var(--dark-gray-light-clr);
    --bg-preloader-page-clr: hsla(217deg 16% 16% / .80);
    --tab-disabled-clr: #445262;
    --drop-zone-clr: var(--dark-text-clr);
}

a
{
    color: #006dff;
}

img,
svg
{
    display: block;

    max-width: 100%;
    height: auto;
}

.svg
{
    fill: currentColor;
}

[data-js-focus-visible] :focus:not([data-focus-visible-added])
{
    outline: none;
}

.logo
{
    display: inline-grid;

    vertical-align: middle;

    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: 18px;
}

.logo__label
{
    transition: fill .24s;

    fill: var(--text-clr);
}

.visually-hidden
{
    position: absolute;

    overflow: hidden;
    clip: rect(0 0 0 0);

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    white-space: nowrap;

    border: 0;

    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
}

.ext-block
{
    display: grid;

    width: 100%;
    min-height: 100vh;
    padding: 16px;

    align-items: center;
    justify-items: center;
}

.ext-block--light
{
    position: relative;

    background-color: var(--bg-input-clr);
}

.ext-block--light::before
{
    position: absolute;
    top: 10vh;
    right: 10vw;
    bottom: 10vh;
    left: 10vw;

    content: '';

    background-image: url('../img/img-enter.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}

.ext-block__wrap
{
    position: relative;
}

.login-box
{
    width: 280px;
}

.login-box__top
{
    display: flex;
    flex-direction: column;

    margin-bottom: var(--gap-2xl);

    text-align: center;

    gap: var(--gap-2xl);
}

.login-box__title
{
    font-size: 24px;
    font-weight: 600;

    margin-top: 32px;
}

.login-box__form:not(:last-child)
{
    margin-bottom: var(--gap-2xl);
}

.login-box__block
{
    margin-bottom: 20px;
}

.login-box__message,
.login-box__bottom,
.login-box__submit
{
    text-align: center;
}

.login-box__message
{
    margin: 16px 0;

    text-align: center;
}

.login-box__bottom
{
    margin-top: 32px;

    text-align: center;
}

.login-box__submit .btn
{
    width: 100%;

    justify-content: center;
}

.main-wrap
{
    display: grid;
    overflow: hidden;

    height: 100vh;

    transition: background-color .24s, color .24s;

    color: var(--text-clr);
    background-color: var(--bg-clr);

    grid-template-areas: 'header header' 'side main';
    grid-template-columns: 280px 1fr;
    grid-template-rows: min-content 1fr;
}

input,
button,
textarea,
select,
optgroup
{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    color: inherit;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=date]::-webkit-calendar-picker-indicator,
input[type=datetime-local]::-webkit-calendar-picker-indicator
{
    width: 12px;
    height: 12px;

    background-image: url('../img/icon-date-light.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

select.form-control,
.c-select__current
{
    background-image: url('../img/icon-select-light.svg');
}

.theme-dark input[type=date]::-webkit-calendar-picker-indicator,
.theme-dark input[type=datetime-local]::-webkit-calendar-picker-indicator
{
    background-image: url('../img/icon-date-dark.svg');
}

.theme-dark select.form-control,
.theme-dark .c-select__current
{
    background-image: url('../img/icon-select-dark.svg');
}

.content--wide
{
    max-width: 820px;
}

.content__top,
.content__main,
.content__bottom
{
    padding-right: 32px;
    padding-left: 32px;
}

.content__top
{
    display: flex;

    padding-top: 24px;
    padding-bottom: 16px;

    align-items: center;
}

.content__top--sticky
{
    position: sticky;
    z-index: 80;
    top: 0;

    transition: background-color .24s;

    background-color: var(--bg-clr);
}

.content__top-main
{
    flex-grow: 1;
}

.content__top-ctrl
{
    display: grid;

    margin-left: auto;

    flex-shrink: 0;
    grid-auto-flow: column;
    gap: 12px;
}

.content__filter
{
    flex-grow: 1;
}

.content__main
{
    padding-top: 16px;
    padding-bottom: 24px;

    flex-grow: 1;
}

.content__main--center
{
    display: flex;

    align-items: center;
    justify-content: center;
}

.content__body
{
    height: 100%;
    min-height: 100%;
}

.content__list
{
    display: flex;
    flex-direction: column;

    gap: var(--indent-md);
}

.content__list--bordered
{
    --border-color: var(--border-page-clr);
    --padding-bottom: var(--indent-md);
}

.content__item
{
    padding-bottom: var(--padding-bottom);

    border-bottom: 1px solid var(--border-color);
}

.content__bottom
{
    padding-top: 8px;
    padding-bottom: 24px;
}

.content__bottom--bt
{
    margin-top: auto;
    padding-top: 24px;

    border-top: 1px solid var(--border-page-clr);
}

.content__block:not(:last-child)
{
    margin-bottom: 32px;
}

.content__block + hr
{
    margin-top: 32px;
    margin-bottom: 32px;
}

.content-top
{
    display: flex;

    flex-grow: 1;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.content-top__ctrl
{
    display: grid;

    margin-left: auto;

    flex-shrink: 0;
    grid-auto-flow: column;
    gap: 12px;
}

hr
{
    height: 1px;
    margin: 0;

    color: var(--border-page-clr);
    border: 0 none;
    background-color: var(--border-page-clr);
}

.btns-wrap
{
    display: grid;

    grid-auto-flow: column;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

.title
{
    font-size: 24px;
    font-weight: 600;

    margin: 0;
}

.box--bordered
{
    padding: 24px;

    transition: border-color .24s;

    border: 1px solid var(--border-page-clr);
    border-radius: 6px;
}

.box__top
{
    margin-bottom: 16px;
}

.block-title
{
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
}

.plug-box
{
    text-align: center;
}

.plug-box__text
{
    font-size: 20px;
    font-weight: bold;

    margin-top: 20px;

    color: var(--gray-clr);
}

.staff
{
    display: grid;

    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: 12px;
}

.staff__cover
{
    position: relative;

    overflow: hidden;

    width: 32px;
    height: 32px;

    border-radius: 50%;
}

.staff img
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    -o-object-fit: cover;
    object-fit: cover;
}

summary
{
    cursor: pointer;
}

.i-title
{
    font-size: var(--font-size);
    font-weight: var(--font-weight-medium);

    display: flex;

    margin-bottom: var(--gap);

    align-items: center;
    gap: var(--gap);
}

.i-title--sm
{
    --gap: var(--gap-sm);
    --font-size: var(--font-size-lg);
}

.i-title--gap-bottom
{
    margin-bottom: var(--gap-xl);
}

.i-title__icon
{
    color: var(--gray-clr);
}

.article
{
    margin-top: -1.6em;
}

.article p,
.article ul,
.article ol,
.article blockquote,
.article h2,
.article h3,
.article figure
{
    line-height: 1.6;

    margin: 1.6em 0;
}

.article h2,
.article h3
{
    font-weight: 600;
}

.article h2
{
    font-size: 18px;
}

.article h3
{
    font-size: 16px;
}

.article a
{
    text-decoration: none;
}

.article figure img
{
    background-color: var(--gray-light-clr);
}

.article figure figcaption
{
    font-size: 12px;

    margin-top: 4px;

    color: var(--gray-clr);
}

.article blockquote
{
    position: relative;

    padding-left: 20px;
}

.article blockquote::before
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 4px;

    content: '';

    border-radius: 2px;
    background-color: currentColor;
}

.article ul,
.article ol
{
    padding-left: 1.6em;
}

.article hr
{
    height: 1px;

    color: var(--gray-light-clr);
    border: 0 none;
    background-color: var(--gray-light-clr);
}

.row
{
    display: flex;

    margin: -10px;

    flex-wrap: wrap;
}

.row--aife
{
    align-items: flex-end;
}

.col
{
    max-width: 100%;
    padding: 10px;

    flex: 1 1 100%;
    flex-grow: 1;
}

.col--half
{
    max-width: 50%;

    flex-basis: 50%;
}

.col--third
{
    max-width: 33.333%;

    flex-basis: 33.333%;
}

.col--fourth
{
    max-width: 25%;

    flex-basis: 25%;
}

.col--fourth-3
{
    max-width: 75%;

    flex-basis: 75%;
}

.w-100
{
    width: 100%;
}

.grid
{
    display: grid;

    gap: 16px;
}

.grid--third
{
    grid-template-columns: repeat(3, 1fr);
}

.line
{
    display: flex;

    gap: 8px;
    flex-wrap: wrap;
}

.line--aic
{
    align-items: center;
}

.line__item--mla
{
    margin-left: auto;
}

.list
{
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;

    list-style: none;

    gap: var(--gap);
}

.list--md
{
    --gap: var(--indent-md);
}

.list--sm
{
    --gap: var(--indent-sm);
}

.header
{
    padding: 24px 32px;

    transition: border-color .24s;

    border-bottom: 1px solid var(--border-page-clr);

    grid-area: header;
}

.header__wrap
{
    display: flex;

    align-items: center;
}

.header__logo
{
    flex-basis: 260px;
}

.header__main
{
    display: flex;

    flex-grow: 1;
    align-items: center;
}

.header__task
{
    max-width: 720px;

    flex-grow: 1;
}

.header__extra
{
    display: grid;

    margin-left: auto;
    margin-left: 24px;

    grid-auto-flow: column;
    align-items: center;
    gap: 24px;
}

.h-search__group
{
    position: relative;
}

.h-search__control.form-control
{
    padding-left: 36px;
}

.h-search__btn
{
    position: absolute;
    top: 50%;
    left: 8px;

    display: block;

    padding: 0;

    transform: translateY(-50%);
    pointer-events: none;

    color: var(--gray-clr);
    border: 0 none;
    background-color: transparent;
}

.h-profile
{
    display: grid;

    grid-auto-flow: column;
    gap: 16px;
}

.h-profile__visual
{
    overflow: hidden;

    width: 32px;
    height: 32px;

    border-radius: 50%;
    background-image: url('../img/sprite-clr.svg#avatar-32');
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.h-profile__btn
{
    padding: 4px;

    cursor: pointer;
    transition: color .24s;

    color: var(--gray-clr);
    border: 0 none;
    background-color: transparent;
}

.q-task:has(.focus-visible),
.q-task:hover
{
    --button-scale: 1.1;
    --button-margin-right: var(--gap-sm);
}

.q-task__form
{
    display: grid;

    grid-template-columns: 1fr auto;
    align-items: center;
}

.q-task__input
{
    font-size: var(--font-size-md);

    height: var(--control-height-lg);
    padding: 0 calc(var(--icon-size-md) + var(--indent-sm) * 2) 0 var(--indent-md);

    transition: .24s;

    border: 1px solid var(--border-page-clr);
    border-radius: var(--radius-lg);
    background-color: var(--bg-clr);
    box-shadow: 0 8px 16px hsla(222, 12%, 27%, .05);

    grid-column: 1/3;
    grid-row: 1/2;
}

.q-task__input:focus
{
    border-color: var(--blue-clr);
}

.q-task__button
{
    margin-right: var(--button-margin-right, var(--gap-md));
    padding: 0;

    transition: var(--duration-sm);
    transform: scale(var(--button-scale));

    color: var(--clr-primary);

    grid-column: 2/3;
    grid-row: 1/2;
}

.side
{
    position: relative;

    display: flex;
    overflow: hidden;
    flex-direction: column;

    padding-right: var(--gap-2xs);

    transition: border-color .24s;

    border-right: 1px solid var(--border-page-clr);

    grid-area: side;
}

.side__wrap
{
    display: flex;
    overflow-y: auto;
    flex-direction: column;

    height: 100%;
    padding: var(--gap-xl) var(--gap-lg) var(--gap-xl) var(--gap-2xl);

    transition: var(--duration-sm);

    flex-grow: 1;
    scrollbar-gutter: stable;
    --scrollbar-size-base: var(--gap-xs);
    --scrollbar-color: var(--bg-input-focus-clr);
    --scrollbar-color-hover: var(--bg-input-focus-clr);
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-color) transparent;
}

.side__wrap::-webkit-scrollbar
{
    width: var(--scrollbar-size-base);
}

.side__wrap::-webkit-scrollbar-track
{
    background-color: transparent;
}

.side__wrap::-webkit-scrollbar-thumb
{
    -webkit-transition: var(--duration-sm);
    transition: var(--duration-sm);

    border-radius: calc(var(--scrollbar-size-base) / 2);
    background-color: var(--scrollbar-color);
}

.side__wrap::-webkit-scrollbar-thumb:hover
{
    background-color: var(--scrollbar-color-hover);
}

.side__main
{
    margin-bottom: 32px;
}

.side__bottom
{
    margin-top: auto;
}

.s-nav__list
{
    font-weight: 600;

    margin: 0;
    padding: 0;

    list-style: none;
}

.s-nav__item:not(:last-child)
{
    margin-bottom: 16px;
}

.s-nav__sublist
{
    margin: 12px 0 0 0;
    padding: 0;

    list-style: none;
}

.s-nav__subitem:not(:last-child)
{
    margin-bottom: 4px;
}

.s-sublink
{
    font-size: 12px;
    line-height: 1;

    position: relative;

    display: flex;

    min-height: 40px;
    padding: 8px 12px 8px 44px;

    transition: background-color .24s, color .24s;
    text-decoration: none;

    color: var(--text-clr);
    border-radius: 6px;

    align-items: center;
    gap: var(--indent-sm);
    --lock-color: var(--gray-clr);
}

.s-sublink:hover,
.s-sublink--active
{
    color: var(--main-clr);
}

.s-sublink:hover
{
    --lock-animation-name: arcMotion;
    --lock-color: var(--success-clr);
}

.s-sublink--active
{
    background-color: var(--bg-s-link-active-clr);
}

.s-sublink--active::before
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: -32px;

    width: 6px;

    content: '';

    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--main-clr);
}

.s-sublink__logo,
.s-sublink__emoji
{
    display: flex;

    flex-shrink: 0;
    flex-basis: 20px;
    align-items: center;
    justify-content: center;
}

.s-sublink__emoji
{
    font-size: 20px;
}

.s-sublink__logo .svg
{
    color: var(--gray-clr);
}

.s-sublink__label
{
    display: flex;

    flex-grow: 1;
    align-items: center;
    gap: var(--indent-xs);
}

.s-sublink__label svg
{
    transition: color .24s;

    color: var(--gray-clr);
}

.s-sublink__qty
{
    font-size: 12px;
    line-height: 1;

    display: inline-block;

    transition: color .24s;

    color: var(--label-clr);
}

.s-link
{
    position: relative;

    display: grid;

    padding: 8px 24px 8px 8px;

    cursor: pointer;
    transition: color .24s;
    text-decoration: none;

    color: var(--text-clr);
    border-radius: 6px;

    grid-template-columns: 24px 1fr;
    align-items: center;
    gap: 12px;
}

.s-link svg
{
    transition: color .24s;

    color: var(--gray-clr);
}

.s-link:hover,
.s-link--active
{
    color: var(--main-clr);
}

.s-link--active
{
    background-color: var(--bg-s-link-active-clr);
}

.s-link--active::before
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: -32px;

    width: 6px;

    content: '';

    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--main-clr);
}

.s-link--active svg
{
    color: var(--main-clr);
}

summary.s-link
{
    position: relative;

    list-style-type: none;
}

summary.s-link::after
{
    position: absolute;
    top: 50%;
    right: 12px;

    width: 0;
    height: 0;
    margin-top: -4px;

    content: '';
    transition: border-color .24s;

    border-width: 4px 0 4px 5px;
    border-style: solid;
    border-color: transparent transparent transparent var(--gray-clr);
}

summary.s-link::-webkit-details-marker
{
    display: none;
}

details[open] summary.s-link
{
    color: var(--main-clr);
}

details[open] summary.s-link svg
{
    color: currentColor;
}

details[open] summary.s-link::after
{
    margin-top: -2.5px;

    transform: rotate(90deg);

    border-color: transparent transparent transparent var(--main-clr);
}

.lock
{
    position: relative;

    width: var(--icon-sm);
    height: var(--icon-sm);
}

.lock__frame
{
    position: absolute;
    bottom: 0;
    left: 50%;

    width: 12px;
    height: 10px;
    margin-left: -6px;

    transition: background-color .4s;

    border-radius: 2px;
    background-color: var(--lock-color);
}

.lock__frame::after
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: 2px;
    height: 2px;
    margin-top: -1px;
    margin-left: -1px;

    content: '';

    border-radius: 50%;
    background-color: var(--bg-clr);
}

.lock__arc
{
    position: absolute;
    top: 0;
    left: 50%;

    width: 8px;
    height: 6px;
    margin-left: -4px;

    transition: border-color .4s;
    animation-name: var(--lock-animation-name, none);
    animation-duration: .4s;
    animation-timing-function: ease-out;

    border-width: 2px 2px 0 2px;
    border-style: solid;
    border-color: var(--lock-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.lock__arc::before
{
    position: absolute;
    top: 100%;
    left: -2px;

    width: 8px;
    height: 4px;

    content: '';
    transition: border-color .4s;

    border-left: 2px solid var(--lock-color);
}

@keyframes arcMotion
{
    0%
    {
        top: 0;
    }

    20%
    {
        top: -4px;
    }

    80%
    {
        top: -4px;
    }

    100%
    {
        top: 0;
    }
}

.main
{
    overflow: hidden;

    padding-top: var(--gap-2xs);
    padding-right: var(--gap-2xs);
    padding-bottom: var(--gap-2xs);

    grid-area: main;
}

.main__wrap
{
    display: flex;
    overflow-y: auto;
    flex-direction: column;

    width: 100%;
    height: 100%;

    transition: var(--duration-sm);

    scrollbar-gutter: stable;
    --scrollbar-size-base: var(--gap-xs);
    --scrollbar-color: var(--bg-input-focus-clr);
    --scrollbar-color-hover: var(--bg-input-focus-clr);
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-color) transparent;
}

.main__wrap::-webkit-scrollbar
{
    width: var(--scrollbar-size-base);
}

.main__wrap::-webkit-scrollbar-track
{
    background-color: transparent;
}

.main__wrap::-webkit-scrollbar-thumb
{
    -webkit-transition: var(--duration-sm);
    transition: var(--duration-sm);

    border-radius: calc(var(--scrollbar-size-base) / 2);
    background-color: var(--scrollbar-color);
}

.main__wrap::-webkit-scrollbar-thumb:hover
{
    background-color: var(--scrollbar-color-hover);
}

.main__no-access,
.main__content
{
    flex-grow: 1;
}

.main__no-access
{
    display: flex;

    align-items: center;
    justify-content: center;
}

.blank
{
    display: flex;
    flex-direction: column;

    text-align: center;

    gap: var(--gap-md);
}

.blank__cover
{
    max-width: -moz-max-content;
    max-width:      max-content;
    margin: 0 auto;

    color: var(--gray-light-clr);
}

.blank__text
{
    color: var(--gray-clr);
}

select.form-control,
.c-select__current
{
    padding-right: 40px;

    background-repeat: no-repeat;
    background-position: top 50% right 12px;
    background-size: 12px 12px;
}

.c-select
{
    position: relative;
}

.c-select__current,
.c-select__item
{
    display: flex;

    width: 100%;

    cursor: pointer;
    text-align: left;

    align-items: center;
}

.c-select__current.is-open
{
    background-color: var(--bg-input-focus-clr);
}

.c-select__ava
{
    position: relative;

    overflow: hidden;

    width: 24px;
    height: 24px;
    margin-right: 12px;

    border-radius: 50%;

    flex-shrink: 0;
}

.c-select__ava img
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    -o-object-fit: cover;
    object-fit: cover;
}

.c-select__label
{
    flex-grow: 1;
}

.c-select__item
{
    padding: 8px 16px;

    border: 0 none;
    background-color: transparent;
}

.c-select__item.active,
.c-select__item:hover
{
    background-color: var(--bg-select-box-hover-clr);
}

.c-select__box
{
    position: absolute;

    display: none;

    min-width: 100%;
}

.c-select__box.is-open
{
    z-index: 8;

    display: block;
    overflow: hidden;

    margin-top: 1px;

    border-radius: 6px;
    background-color: var(--bg-select-box-clr);
    box-shadow: 0 12px 32px rgba(0, 0, 0, .05);
}

.label
{
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    color: var(--label-clr);
}

.q-note
{
    position: relative;

    display: inline-block;

    padding-left: 20px;

    animation: blink 1.2s linear 2;
    vertical-align: middle;

    opacity: 0;
}

.q-note::before
{
    position: absolute;
    top: 50%;
    left: 0;

    width: 16px;
    height: 16px;
    margin-top: -8px;

    content: '';

    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
}

.q-note--saved
{
    color: var(--success-clr);
}

.q-note--saved::before
{
    background-image: url('../img/sprite-clr.svg#saved');
}

.filter-control
{
    font-weight: 500;

    display: block;

    width: 160px;
    min-height: 30px;
    padding: 4px 12px;

    border: 0 none;
    border-radius: 6px;
    background-color: var(--bg-input-clr);
}

.form-item .label
{
    display: grid;

    margin-bottom: 8px;

    gap: 8px;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
}

.form-item[data-suffix]
{
    position: relative;
}

.form-item[data-suffix]::after
{
    position: absolute;
    top: 50%;
    right: 8px;

    content: attr(data-suffix);
    transform: translateY(-50%);

    color: var(--gray-clr);
}

.form-item[data-suffix] input
{
    padding-right: 40px;
}

.line-filter
{
    display: flex;

    align-items: center;
    gap: var(--gap-md);
}

.line-filter__item--mla
{
    margin-left: auto;
}

.line-form
{
    display: grid;

    grid-template-columns: auto minmax(200px, 1fr);
    gap: 8px;
    align-items: center;
}

.line-form .ss-main .ss-single-selected
{
    height: 32px;
}

.form-comment
{
    display: grid;

    width: 100%;

    grid-template-columns: 40px 1fr;
    -moz-column-gap: 20px;
    column-gap: 20px;
    row-gap: 10px;
}

.form-comment__ava
{
    position: relative;

    overflow: hidden;

    width: 40px;
    height: 40px;

    border-radius: 50%;
}

.form-comment__ava img
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    -o-object-fit: cover;
    object-fit: cover;
}

.form-comment__form
{
    position: relative;
}

.form-comment__control .form-control
{
    font-size: 15px;

    padding-right: 40px;
    padding-left: 40px;
}

.form-comment__attach,
.form-comment__submit
{
    position: absolute;
    top: 8px;
}

.form-comment__attach
{
    left: 8px;
}

.form-comment__attach .file-attach
{
    padding: 4px;
}

.form-comment__submit
{
    right: 12px;
}

.form-comment__files
{
    grid-column: 2/3;
}

.ava-file
{
    cursor: pointer;

    color: var(--gray-clr);
}

.ava-file:hover
{
    color: var(--gray-hover-clr);
}

.ava-file:hover .ava-file__cover
{
    background-color: var(--gray-hover-clr);
}

.ava-file__main
{
    display: flex;

    align-items: center;
}

.ava-file__cover
{
    position: relative;

    display: flex;
    overflow: hidden;

    width: 40px;
    height: 40px;
    margin-right: 16px;

    color: var(--white-clr);
    border-radius: 50%;
    background-color: var(--gray-clr);

    align-items: center;
    justify-content: center;
}

.ava-file__cover img
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    -o-object-fit: cover;
    object-fit: cover;
}

.ava-file__label
{
    font-weight: 500;
}

.form-error
{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;

    display: block;

    color: var(--urgent-clr);
}

.value
{
    display: inline-flex;

    height: var(--control-height-sm);

    vertical-align: middle;

    align-items: center;
}

.value--bg
{
    padding: 0 var(--indent-md);

    border-radius: var(--radius-md);
    background-color: var(--bg-input-clr);
}

.form-group,
.group
{
    display: flex;
    flex-direction: column;

    gap: var(--indent-xs);
}

.form-group--center,
.group--center
{
    align-items: center;
}

.form-group--center .form-control,
.group--center .form-control
{
    text-align: center;
}

.form-group--fs,
.group--fs
{
    align-items: flex-start;
}

.form-group--row,
.group--row
{
    grid-template-columns: auto 1fr;
    align-items: center;
}

.form-group + .form-group,
.group + .form-group
{
    margin-top: var(--indent-md);
}

.form-field
{
    display: inline-flex;

    min-height: var(--control-height);
    padding-top: 0;
    padding-bottom: 0;

    transition: background-color .24s;

    border-radius: var(--radius-base);
}

.form-field.disabled
{
    cursor: not-allowed;
}

.form-field.disabled button,
.form-field.disabled label
{
    pointer-events: none;
}

.form-field--text,
.form-field--select
{
    width: 100%;

    align-items: center;
}

.form-field--select .ss-arrow span
{
    border-color: var(--placeholder-clr);
}

.form-field--select .ss-value
{
    height: calc(var(--control-height) - 8);
    padding: 2px calc(var(--control-height) / 4);
}

.form-field--textarea
{
    width: 100%;

    align-items: start;
}

.form-field--textarea .form-control
{
    padding-top: var(--indent-xs);
    padding-bottom: var(--indent-xs);
}

.form-field--textarea .form-field__side
{
    margin-top: var(--indent-xs);
}

.form-field--files
{
    width: 100%;
}

.form-field--files .file-attach
{
    margin-right: calc(var(--icon-size-base) / 4 * -1);
}

.form-field--primary
{
    --color-h: var(--clr-primary-h);
    --color-s: var(--clr-primary-s);
    --color-l: var(--clr-primary-l);
    --color: hsl(var(--color-h), var(--color-s), var(--color-l));
}

.form-field--s
{
    font-size: var(--font-size-sm);

    padding-right: var(--indent-sm);
    padding-left: var(--indent-sm);

    --control-height: var(--control-height-sm);
    --line-height: var(--icon-size-sm);
    -moz-column-gap: calc(var(--indent-xs) / 2);
    column-gap: calc(var(--indent-xs) / 2);
}

.form-field--s .ss-content
{
    right: calc((var(--indent-sm) + 1px) * -1);
    left: calc((var(--indent-sm) + 1px) * -1);
}

.form-field--s .ss-content .ss-list .ss-option
{
    padding-right: var(--indent-sm);
    padding-left: var(--indent-sm);
}

.form-field--m
{
    font-size: var(--font-size-md);

    padding-right: var(--indent-md);
    padding-left: var(--indent-md);

    --control-height: var(--control-height-md);
    --line-height: 1.5;
    -moz-column-gap: var(--indent-xs);
    column-gap: var(--indent-xs);
}

.form-field--m .ss-content
{
    right: calc((var(--indent-md) + 1px) * -1);
    left: calc((var(--indent-md) + 1px) * -1);
}

.form-field--m .ss-content .ss-list .ss-option
{
    padding-right: var(--indent-md);
    padding-left: var(--indent-md);
}

.form-field--l
{
    font-size: 1.7em;
    font-weight: 700;

    padding-right: var(--indent-md);
    padding-left: var(--indent-md);

    --control-height: var(--control-height-lg);
    --line-height: normal;
}

.form-field--1
{
    border-color: var(--color-border);
    background-color: var(--clr-white);

    --color-placeholder: var(--clr-placeholder-type-1);
    --color-border: hsl(var(--color-h), 12%, 88%);
}

.form-field--1 .ss-content
{
    border-color: var(--color-border);
}

.form-field--1 .ss-value
{
    background-color: hsl(216, 28%, 98%);
}

.form-field--1:hover
{
    border-color: hsl(var(--color-h), 14%, 82%);
}

.form-field--1.form-field--focus
{
    border-color: var(--color);
}

.form-field--1.form-field--focus .form-field__icon
{
    color: var(--color);
}

.form-field--1.has-error
{
    border-color: var(--clr-error);
}

.form-field--1.disabled
{
    border-color: var(--color-border);
    background-color: hsl(var(--color-h), 28%, 98%);
}

.form-field--1.disabled:hover
{
    border-color: var(--color-border);
}

.form-field--2
{
    background-color: var(--bg-input-clr);

    --color-placeholder: var(--clr-placeholder-type-2);
}

.form-field--2 .ss-content
{
    box-shadow: 0 12px 36px hsla(0, 0%, 0%, .05);
}

.form-field--2 .ss-value
{
    background-color: var(--white-clr);
}

.form-field--2:hover
{
    background-color: var(--bg-input-focus-clr);
}

.form-field--2.form-field--focus
{
    background-color: var(--bg-input-focus-clr);
}

.form-field--2.form-field--focus.has-error
{
    background-color: var(--bg-input-focus-clr);
}

.form-field--2.has-error
{
    background-color: hsl(342, 50%, 96%);
}

.form-field--2.disabled
{
    background-color: hsl(216, 20%, 98%);
}

.form-field--2.disabled::-moz-placeholder
{
    color: hsl(205, 13%, 82%);
}

.form-field--2.disabled::placeholder
{
    color: hsl(205, 13%, 82%);
}

.form-field--2.disabled svg
{
    fill: hsl(205, 13%, 82%);
}

.form-field__btn,
.form-field__attach
{
    display: inline-block;

    vertical-align: middle;

    color: var(--color-placeholder);

    flex-shrink: 0;
}

.form-field__side
{
    flex-shrink: 0;
}

.form-field__side .form-text
{
    line-height: 1;
}

.form-field__attach,
.form-field__side--left
{
    margin-left: calc(var(--indent-xs) / -2);
}

.form-field__btn,
.form-field__side--right
{
    margin-right: calc(var(--indent-xs) / -2);
}

.form-field__btn,
.form-field__attach
{
    padding: 0;

    cursor: pointer;

    border: none;
    background-color: transparent;
}

.form-field__btn:hover,
.form-field__attach:hover
{
    color: var(--color);
}

.form-icon,
.form-btn,
.form-text
{
    display: block;

    transition: color .24s;

    color: var(--placeholder-clr);
}

.form-btn
{
    padding: 0;

    cursor: pointer;

    border: 0 none;
    background-color: transparent;
}

.form-label
{
    font-size: .8575em;

    display: grid;

    transition: color .24s;

    color: var(--label-clr);

    gap: 8px;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
}

.form-control
{
    line-height: var(--line-height);

    min-height: var(--control-height);
    padding: 0;

    flex-grow: 1;
}

.form-control[type=password]:not(:-moz-placeholder-shown)
{
    font-weight: bold;

    letter-spacing: 4px;
}

.form-control[type=password]:not(:placeholder-shown)
{
    font-weight: bold;

    letter-spacing: 4px;
}

.form-control[type=date]
{
    cursor: pointer;
}

.form-control[type=date]::-webkit-calendar-picker-indicator
{
    cursor: pointer;
}

.form-control::-moz-placeholder
{
    color: var(--placeholder-clr);
}

.form-control::placeholder
{
    color: var(--placeholder-clr);
}

.form-control:disabled
{
    cursor: inherit;

    background-color: transparent;
}

.file-btn__input
{
    position: absolute;

    overflow: hidden;
    clip: rect(0 0 0 0);

    width: 1px;
    height: 1px;
}

.file-btn__input:focus + svg
{
    outline: 1px auto var(--clr-primary);
    outline-offset: 4px;
}

.file-attach
{
    display: grid;

    min-height: var(--control-height);

    flex-grow: 1;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.file-attach__main
{
    display: flex;

    align-items: center;
}

.file-attach__cover
{
    margin-right: var(--indent-xs);
}

.file-attach__cover--error
{
    color: var(--urgent-clr);
}

.file-attach__placeholder
{
    color: var(--color-placeholder);

    flex-grow: 1;
}

.file-attach__label
{
    display: flex;

    align-items: center;
}

.file-attach__input
{
    position: absolute;

    overflow: hidden;
    clip: rect(0 0 0 0);

    width: 1px;
    height: 1px;
}

.file-attach__btn
{
    font-size: var(--font-size-sm);
    font-weight: 500;

    display: block;

    padding: calc(var(--indent-xs) / 2) var(--indent-base);

    cursor: pointer;

    color: var(--clr-primary);
    border: 0 none;
    border-radius: var(--radius-sm);
    background-color: var(--clr-white);
}

.check-btn.checked
{
    color: var(--clr-primary);
}

.focus-btn:focus
{
    color: var(--clr-primary);
}

input[type=radio]:focus-visible + *,
input[type=checkbox]:focus-visible + *,
input[type=file]:focus-visible + *
{
    outline: 1px auto var(--clr-primary);
    outline-offset: 4px;
}

.switch
{
    position: relative;

    display: inline-flex;

    cursor: pointer;
    vertical-align: middle;

    --border-main: hsl(214, 12%, 88%);
    --border-hover: hsl(214, 12%, 80%);
}

.switch::before
{
    position: absolute;
    top: calc(var(--indent-xs) / -2);
    right: calc(var(--indent-xs) / -2);
    bottom: calc(var(--indent-xs) / -2);
    left: calc(var(--indent-xs) / -2);

    content: '';
}

.switch + .switch
{
    margin-top: var(--indent-base);
}

.switch--disabled
{
    cursor: not-allowed;
}

.switch input
{
    position: absolute;

    overflow: hidden;
    clip: rect(0 0 0 0);

    width: 1px;
    height: 1px;
    margin: -1px;

    border: 0;

    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
}

.switch input:disabled + .switch__cover
{
    background-color: hsl(214, 12%, 98%);
}

.switch input:disabled:checked + .switch__cover
{
    color: var(--clr-white);
    border-color: var(--gray-clr);
    background-color: var(--gray-clr);
}

.switch__cover
{
    position: relative;

    display: inline-flex;

    content: '';
    transition: border-color .24s, background-color .24s;

    border: 1px solid var(--bg-border-switch-clr);

    align-items: center;
    justify-content: center;
}

.switch__cover svg
{
    opacity: 0;
}

.switch__label
{
    font-size: var(--font-size);

    margin-left: var(--indent-base);
    padding-top: var(--top-indent);
}

.checkbox--s,
.radio--s,
.toggle--s
{
    --cover-size: 16px;
    --font-size: var(--font-size-sm);
    --top-indent: 0;
}

.checkbox--m,
.radio--m,
.toggle--m
{
    --cover-size: 24px;
    --font-size: var(--font-size-base);
    --top-indent: 2px;
}

.checkbox--l,
.radio--l,
.toggle--l
{
    --cover-size: 32px;
    --font-size: var(--font-size-xl);
    --top-indent: 2px;
}

.checkbox__cover,
.radio__cover
{
    width: var(--cover-size);
    height: var(--cover-size);

    flex-shrink: 0;
}

.checkbox input:checked + .checkbox__cover
{
    color: var(--clr-white);
    border-color: var(--clr-primary);
    background-color: var(--clr-primary);
}

.checkbox input:checked + .checkbox__cover .svg
{
    opacity: 1;
}

.checkbox__cover
{
    border-radius: var(--radius-sm);
    background-color: var(--clr-white);
}

.radio--s
{
    --icon-size: 6px;
}

.radio--m
{
    --icon-size: 10px;
}

.radio--l
{
    --icon-size: 12px;
}

.radio input:checked + .radio__cover
{
    border-color: hsl(var(--clr-primary-h), var(--clr-primary-s), 94%);
    background-color: hsl(var(--clr-primary-h), var(--clr-primary-s), 94%);
}

.radio input:checked + .radio__cover::after
{
    background-color: var(--clr-primary);
}

.radio__cover
{
    border-radius: 50%;
}

.radio__cover::after
{
    width: var(--icon-size);
    height: var(--icon-size);

    content: '';

    border-radius: inherit;
}

.toggle--s
{
    --icon-size: 12px;
}

.toggle--m
{
    --icon-size: 18px;
}

.toggle input:checked + .toggle__cover
{
    border-color: var(--bg-rail-switch-active-clr);
    background-color: var(--bg-rail-switch-active-clr);
}

.toggle input:checked + .toggle__cover::after
{
    left: auto;
    left: calc(100% - var(--icon-size) - 2px);

    background-color: var(--clr-primary);
}

.toggle input:disabled + .toggle__cover::after
{
    background-color: hsl(214, 12%, 88%);
}

.toggle__cover
{
    width: calc(var(--cover-size) * 2);
    height: var(--cover-size);

    border-radius: calc(var(--cover-size) / 2);
}

.toggle__cover::after
{
    position: absolute;
    top: 50%;
    left: 2px;

    width: var(--icon-size);
    height: var(--icon-size);
    margin-top: calc(var(--icon-size) / 2 * -1);

    content: '';
    transition: left .24s, background-color .24s;

    border-radius: 50%;
    background-color: var(--bg-caret-switch-clr);
}

.form-caption
{
    font-size: var(--font-size-xs);

    margin-top: calc(var(--indent-xs) / 2);
}

.form-caption--right
{
    text-align: right;
}

.form-caption--error
{
    color: var(--error-clr);
}

.e-control
{
    display: flex;

    padding: calc(var(--indent-xs) / 2) var(--indent-xs) calc(var(--indent-xs) / 2) var(--indent-md);

    transition: border-color .24s, background-color .24s;

    border-radius: var(--radius-md);
    box-shadow: inset 0 0 0 1px var(--border-page-clr);

    align-items: center;
    gap: var(--indent-xs);
}

.e-control:hover
{
    background-color: var(--bg-input-clr);
}

.e-control.is-active
{
    background-color: var(--bg-input-focus-clr);
}

.e-control--extra
{
    margin-top: var(--indent-md);
}

.e-control--extra:hover
{
    background-color: transparent;
}

.e-control__input
{
    height: var(--indent-lg);
    padding: 0;

    flex-grow: 1;
}

.e-control .btn
{
    flex-shrink: 0;
}

.stage-item
{
    display: flex;

    padding: calc(var(--indent-xs) / 2) var(--indent-xs) calc(var(--indent-xs) / 2) var(--indent-xs);

    transition: border-color .24s, background-color .24s;

    border-radius: var(--radius-md);
    box-shadow: inset 0 0 0 1px var(--border-page-clr);

    align-items: center;
    gap: var(--indent-xs);
}

.stage-item:hover
{
    background-color: var(--bg-input-clr);

    --extra-opacity: 1;
}

.stage-item__main
{
    display: grid;

    flex-grow: 1;
    grid-template-columns: var(--icon-sm) 1fr;
    gap: var(--gap-xs);
    align-items: center;
}

.stage-item__main svg
{
    color: var(--gray-clr);
}

.stage-item__extra
{
    transition: var(--duration-sm);

    opacity: var(--extra-opacity, 0);
}

.sortable-placeholder
{
    width: 100%;
    height: calc(var(--indent-lg) + var(--indent-xs) * 2);

    border-radius: var(--radius-md);
    background-color: var(--border-page-clr);
    box-shadow: inset 0 0 0 1px var(--border-page-clr);
}

.btn
{
    line-height: 16px;

    display: inline-grid;

    min-height: var(--height);

    cursor: pointer;
    transition: background-color .24s;
    text-decoration: none;

    color: var(--white-clr);
    border: 0 none;
    border-radius: 6px;
    background-color: transparent;

    grid-auto-flow: column;
    gap: 8px;
    justify-content: start;
    align-items: center;
}

.btn--primary
{
    --color-h: var(--clr-primary-h);
    --color-s: var(--clr-primary-s);
    --color-l: var(--clr-primary-l);
    --color: hsl(var(--color-h), var(--color-s), var(--color-l));
}

.btn--gray
{
    --color-h: 205;
    --color-s: 14%;
    --color-l: 66%;
    --color: hsl(var(--color-h), var(--color-s), var(--color-l));
}

.btn--s
{
    padding: 0 var(--indent-sm);

    --height: var(--control-height-sm);
}

.btn--m
{
    padding: 0 var(--indent-md);

    --height: var(--control-height-md);
}

.btn--l
{
    font-size: var(--font-size-md);

    padding: 0 var(--indent-lg);

    --height: var(--control-height-lg);
}

.btn--icon
{
    width: var(--height);
    padding: 4px;

    justify-content: center;
}

.btn--1
{
    font-weight: var(--font-weight-semibold);

    color: var(--white-clr);
    background-color: var(--color);
}

.btn--1:hover
{
    --color-l: 45%;
}

.btn--1:active
{
    --color-l: 39%;
}

.btn--1:disabled
{
    --color-s: 12%;
    --color-l: 88%;
}

.btn--2
{
    color: var(--color);
    border: 1px solid currentColor;
}

.btn--2:hover
{
    background-color: hsl(var(--color-h) var(--color-s) var(--color-l)/.08);
}

.btn--2:active
{
    background-color: hsl(var(--color-h) var(--color-s) var(--color-l)/.16);
}

.btn--2:disabled
{
    background-color: transparent;

    --color-s: 14%;
    --color-l: 66%;
}

.btn--3
{
    color: var(--color);
    background-color: hsl(var(--color-h), var(--color-s), 94%);
}

.btn--3:hover
{
    background-color: hsl(var(--color-h), var(--color-s), 89%);
}

.btn--3:active
{
    background-color: hsl(var(--color-h), var(--color-s), 82%);
}

.btn--3:disabled
{
    color: hsl(var(--color-h), 14%, 66%);
    background-color: hsl(var(--color-h), 12%, 88%);
}

.btn--4
{
    font-weight: var(--font-weight-medium);

    color: var(--color);
}

.btn--4:hover,
.btn--4.is-active
{
    background-color: hsl(var(--color-h) var(--color-s) var(--color-l)/.08);

    --color-l: 45%;
}

.btn--4:active
{
    background-color: hsl(var(--color-h) var(--color-s) var(--color-l)/.2);
}

.btn--4:disabled
{
    cursor: not-allowed;

    color: hsl(var(--color-h), var(--color-s), 88%);
    background-color: transparent;
}

.btn--transparent
{
    font-weight: 500;

    padding-right: 0;
    padding-left: 0;

    color: var(--gray-clr);
    background-color: transparent;
}

.btn--bordered
{
    color: var(--main-clr);
    background-color: transparent;
    box-shadow: inset 0 0 0 1px currentColor;
}

.btn--full
{
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;

    justify-content: center;
}

.btn--success
{
    background-color: var(--success-clr);
}

.btn--success:hover
{
    background-color: var(--success-hover-clr);
}

.btn--main
{
    background-color: var(--main-clr);
}

.btn--main:hover
{
    background-color: var(--main-hover-clr);
}

.btn--link
{
    min-height: -moz-min-content;
    min-height:      min-content;
    padding: 0;
}

.btn--link:hover
{
    background-color: transparent;
}

.btn-tswitch
{
    position: relative;

    display: block;

    width: 56px;
    height: 32px;
    padding: 0;

    cursor: pointer;
    transition: background-color .24s;

    border: 0 none;
    border-radius: 16px;
    background-color: var(--bg-tswitch-clr);
}

.btn-tswitch::before,
.btn-tswitch::after
{
    position: absolute;
    top: 50%;
    left: 4px;

    width: 24px;
    height: 24px;

    content: '';
    transition: .4s;
    transform: translateY(-50%);

    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}

.btn-tswitch::before
{
    background-image: url('../img/img-moon-dark.svg');
}

.btn-tswitch::after
{
    opacity: 0;
    background-image: url('../img/img-moon-light.svg');
}

.btn-tswitch.theme-dark::before,
.btn-tswitch.theme-dark::after
{
    left: 28px;
}

.btn-tswitch.theme-dark::before
{
    opacity: 0;
}

.btn-tswitch.theme-dark::after
{
    opacity: 1;
}

.ibtn
{
    display: inline-block;

    padding: 0;

    cursor: pointer;
    transition: color .24s;
    vertical-align: middle;

    border: 0 none;
    background-color: transparent;
}

.ibtn--gray
{
    color: var(--gray-clr);
}

.ibtn--gray:hover
{
    color: var(--gray-hover-clr);
}

.ibtn--main
{
    color: var(--main-clr);
}

.ibtn--main:hover
{
    color: var(--main-hover-clr);
}

.ibtn--cross-small
{
    color: var(--cross-small-clr);
}

.ibtn--bordered
{
    padding: 8px;

    border-radius: 6px;
    box-shadow: inset 0 0 0 1px currentColor;
}

.btn-close
{
    display: flex;

    width: 32px;
    height: 32px;
    padding: 0;

    cursor: pointer;

    color: var(--label-clr);
    border: 0 none;
    background-color: transparent;

    align-items: center;
    justify-content: center;
}

.link
{
    font-weight: 500;

    text-decoration: none;

    color: var(--text-clr);
}

.link--main
{
    color: var(--main-clr);
}

.btn-add
{
    display: flex;

    width: var(--control-height-sm);
    height: var(--control-height-sm);

    transition: .24s;

    color: var(--clr-primary);
    border-radius: var(--radius-md);
    background-color: var(--bg-input-clr);

    align-items: center;
    justify-content: center;
}

.btn-add:hover
{
    background-color: var(--bg-input-focus-clr);
}

.btn-add.is-active
{
    color: var(--clr-white);
    background-color: var(--clr-primary);
}

.btn-add--circle
{
    border-radius: 50%;
}

.tabs__list
{
    font-weight: 600;

    display: flex;

    margin: 0;
    padding: 0;

    list-style: none;
}

.tabs__item:first-child button
{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.tabs__item:last-child button
{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.tabs__btn
{
    font-size: 12px;
    line-height: 1;

    padding: 10px 16px;

    cursor: pointer;
    white-space: nowrap;

    color: var(--text-clr);
    border: 0 none;
    background-color: var(--bg-input-clr);
}

.tabs__btn.active
{
    color: var(--white-clr);
    background-color: var(--main-clr);
}

.tbl-wrap
{
    overflow-x: auto;

    margin-right: -32px;
    margin-left: -32px;

    -webkit-overflow-scrolling: touch;
}

.tbl-wrap .tbl th:first-child,
.tbl-wrap .tbl td:first-child
{
    padding-left: 32px;
}

.tbl-wrap .tbl th:last-child,
.tbl-wrap .tbl td:last-child
{
    padding-right: 32px;
}

.tbl,
.table
{
    line-height: 1.2;

    width: 100%;

    border-collapse: collapse;
}

.tbl th,
.tbl td,
.table th,
.table td
{
    padding-right: 8px;
    padding-left: 8px;

    border-bottom: 1px solid var(--border-page-clr);
}

.tbl th,
.table th
{
    font-size: 12px;
    font-weight: 500;

    padding-top: 8px;
    padding-bottom: 8px;

    transition: border-color .24s, color .24s;
    text-align: left;

    color: var(--label-clr);
}

.tbl td,
.table td
{
    position: relative;

    transition: border-color .24s;
}

.table
{
    margin-top: var(--indent-sm);
}

.table td
{
    padding-top: 4px;
    padding-bottom: 4px;
}

.tbl__col--xs
{
    width: 56px;
}

.tbl__col--project
{
    width: 240px;
}

.tbl__col--status
{
    width: 176px;
}

.tbl__col--main
{
    width: 32%;
}

.tbl__col--grow
{
    width: 100%;
}

.tbl td
{
    height: 32px;
}

.tbl td a
{
    text-decoration: none;

    color: currentColor;
}

.tbl tbody tr
{
    cursor: pointer;
}

.tbl tbody tr:hover td
{
    background-color: var(--bg-table-row-hover);
}

.task-status
{
    font-size: 12px;
    line-height: 1.2;

    display: inline-grid;

    color: var(--label-clr);

    grid-template-columns: 32px max-content;
    gap: 12px;
    align-items: center;
}

.task-line
{
    font-size: var(--font-size-sm);

    transition: color var(--duration-sm);

    color: var(--text-clr);
}

.unread .task-name
{
    font-weight: bold;
}

.closed
{
    color: var(--gray-clr);

    --members-opacity: .5;
}

.closed .task-status
{
    color: inherit;
}

.urgent .task-status
{
    color: var(--urgent-clr);
}

.soon .task-status
{
    color: var(--orange-clr);
}

.done .task-status
{
    color: var(--label-clr);
}

.task-name
{
    display: flex;

    min-height: 56px;
    padding-top: 12px;
    padding-bottom: 12px;

    align-items: center;
    -moz-column-gap: 8px;
    column-gap: 8px;
}

.task-name__qty
{
    margin-left: auto;
}

.notify-qty
{
    font-size: var(--font-size-xs);
    font-weight: bold;
    line-height: 12px;

    display: block;

    min-width: 16px;
    padding: 2px 6px;

    text-align: center;

    color: var(--white-clr);
    border-radius: 8px;
    background-color: var(--urgent-clr);
}

.task-icon
{
    transition: color .24s;

    color: var(--bg-table-icon);
}

.task-icon--urgent
{
    color: var(--bg-table-icon-urgent);
}

.task-icon--done
{
    color: var(--success-clr);
}

.col-id
{
    color: var(--label-clr);
}

.col-link
{
    font-weight: 600;

    display: block;

    padding-top: 12px;
    padding-bottom: 12px;
}

.col-title
{
    font-size: var(--font-size-xl);

    padding: var(--gap-md) 0 var(--gap-xs);
}

.col-title--gray
{
    color: var(--gray-clr);
}

.pu-task-top__wrap
{
    display: flex;

    width: 100%;

    align-items: center;
}

.pu-task-top__title
{
    font-size: var(--font-size-xl);
    font-weight: bold;
    line-height: normal;
}

.pu-task-top__close
{
    margin-right: 24px;

    flex-shrink: 0;
}

.pu-task-top__info
{
    display: grid;

    flex-grow: 1;
    grid-template-columns: max-content max-content;
    align-items: center;
    gap: 4px;
}

.pu-task-top__number
{
    font-weight: 600;
}

.pu-task-top__ctrl
{
    display: grid;

    margin-left: auto;

    flex-shrink: 0;
    grid-auto-flow: column;
    align-items: center;
    gap: 12px;
}

.task-persons
{
    display: grid;

    grid-template-columns: 1fr 1fr;
    justify-content: start;
    align-items: start;
    gap: 16px;
}

.task-persons__one
{
    display: grid;

    grid-template-columns: max-content 1fr;
    align-items: center;
    gap: 12px;
}

.task-persons__label
{
    grid-column: 1/3;
}

.task-persons__ava
{
    position: relative;

    overflow: hidden;

    width: 32px;
    height: 32px;

    border-radius: 50%;
}

.task-persons__ava img
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    -o-object-fit: cover;
    object-fit: cover;
}

.task-persons__name
{
    font-weight: 600;
}

.task-info__main
{
    margin-bottom: 24px;
}

.task-info__label
{
    margin-bottom: 4px;
}

.task-info__title
{
    font-size: 20px;
    font-weight: bold;
}

.task-info__extra
{
    display: grid;
}

.task-info__bottom
{
    margin-top: var(--indent-md);
}

.task-comments__top
{
    margin-bottom: 12px;
}

.task-comments__item
{
    padding-left: var(--icon-size-lg);
}

.task-comments__item:not(:last-child)
{
    margin-bottom: var(--indent-xl);
}

.task-comment
{
    font-size: 14px;
}

.task-comment:hover
{
    --bottom-btn-opacity: 1;
    --controls-opacity: 1;
}

.task-comment__top
{
    display: grid;

    margin-bottom: var(--indent-xs);
    margin-left: calc(var(--icon-size-lg) * -1);

    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    gap: var(--indent-sm);
}

.task-comment__controls
{
    display: flex;

    align-items: center;
    gap: var(--gap-xs);
}

.task-comment__control
{
    transition: var(--duration-sm);

    opacity: var(--controls-opacity, 0);
}

.task-comment__ava
{
    position: relative;

    overflow: hidden;

    width: var(--icon-size-lg);
    height: var(--icon-size-lg);

    border-radius: 50%;
}

.task-comment__ava img
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    -o-object-fit: cover;
    object-fit: cover;
}

.task-comment__name
{
    font-weight: 600;
}

.task-comment__main,
.task-comment__bottom
{
    padding-left: var(--indent-sm);
}

.task-comment__bottom
{
    margin-top: var(--gap-xs);
}

.task-comment__answer
{
    margin-bottom: var(--gap-xs);
}

.task-comment__text a
{
    display: inline-block;
    overflow: hidden;

    max-width: 100%;

    vertical-align: bottom;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.task-comment__bottom-btn
{
    margin-top: var(--gap-xs);

    transition: var(--duration-sm);

    opacity: var(--bottom-btn-opacity, 0);
}

.task-head
{
    display: grid;

    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: 12px;
}

.task-head__number
{
    font-weight: 600;
}

.task-head__number strong
{
    font-weight: inherit;

    transition: color .24s;

    color: var(--label-clr);
}

.task-head__date
{
    font-size: 13px;
    line-height: 18px;

    transition: color .24s;

    color: var(--label-clr);
}

.task-date
{
    font-size: 13px;
    line-height: 18px;

    transition: color .24s;

    color: var(--label-clr);
}

.task-relations__label
{
    font-size: var(--font-size-sm);
    font-weight: bold;

    margin-bottom: var(--indent-sm);

    transition: color .24s;

    color: var(--label-clr);
}

.text p
{
    margin: 0;
}

.text p:not(:last-child)
{
    margin-bottom: 1em;
}

.text--sm
{
    font-size: var(--font-size-sm);
}

.task-box
{
    overflow: hidden;

    padding: var(--indent-sm) 0;

    border-radius: var(--radius-lg);
}

.task-box__top
{
    position: sticky;
    z-index: 2;
    top: 0;

    display: flex;

    width: 100%;
    padding: var(--indent-sm) 0;

    list-style: none;

    transition: .24s;

    border-bottom: 1px solid var(--border-page-clr);
    background-color: var(--bg-clr);

    gap: var(--indent-sm);
    align-items: center;
}

.task-box__title
{
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
}

.task-box__title span
{
    font-weight: normal;

    color: var(--gray-hover-clr);
}

.task-box__item
{
    position: relative;

    transition: .8s;

    border-bottom: 1px solid var(--border-page-clr);
}

.task-box__item--blank
{
    display: grid;

    padding: var(--gap-md) 0;

    color: var(--gray-hover-clr);
    border-bottom: none;

    grid-template-columns: var(--icon-md) 1fr;
    gap: var(--indent-md);
}

.comment-answer
{
    font-size: var(--font-size-xs);

    position: relative;

    display: block;

    padding: var(--gap-xs) var(--gap-sm);

    text-decoration: none;

    color: var(--text-clr);
    border-radius: 2px var(--radius-md) var(--radius-md) 2px;
    background-color: var(--blue-bg-answer);
}

.comment-answer::before
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 2px;

    content: '';

    border-radius: 2px;
    background-color: var(--blue-border-answer);
}

.comment-answer__top
{
    margin-bottom: var(--gap-xs);
}

.comment-answer__name
{
    font-weight: 600;
}

.rel-task
{
    display: flex;

    transition: var(--duration-sm);

    border-radius: var(--radius-lg);
    background-color: var(--bg-clr);

    align-items: center;
    gap: var(--gap-md);
}

.rel-task:hover
{
    box-shadow: 0 6px 18px 0 rgba(60, 65, 77, .05);

    --extra-opacity: 1;
}

.rel-task__main
{
    display: flex;

    padding: var(--gap-sm) 0 var(--gap-sm) var(--gap-md);

    text-align: left;

    flex-grow: 1;
    gap: var(--gap-xs);
    align-items: center;
}

.rel-task__cover
{
    color: var(--gray-clr);

    flex-shrink: 0;
}

.rel-task__info
{
    display: flex;
    flex-direction: column;

    gap: var(--gap-2xs);
}

.rel-task__title
{
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    line-height: var(--gap-md);

    transition: var(--duration-sm);

    color: var(--text-clr);
}

.rel-task__extra
{
    padding: var(--gap-xs) var(--gap-xs) var(--gap-xs) 0;

    transition: var(--duration-sm);

    opacity: var(--extra-opacity, 0);
}

.serv-tbl__head
{
    font-size: 12px;

    padding: 8px 12px 8px 44px;

    color: var(--label-clr);
}

.serv-tbl__row
{
    display: grid;

    grid-template-columns: 1fr 110px 110px;
    gap: 16px;
}

.serv-tbl__top
{
    position: relative;

    padding: 12px 12px 12px 44px;

    list-style-type: none;

    cursor: pointer;
}

.serv-tbl__top::-webkit-details-marker
{
    display: none;
}

.serv-tbl__top:hover .serv-tbl__delete,
.serv-tbl__row:hover .serv-tbl__delete
{
    opacity: 1;
}

.serv-tbl__col .btn
{
    width: 100%;

    justify-content: center;
}

.serv-tbl__toggle
{
    position: absolute;
    top: 50%;
    left: 12px;

    display: block;

    width: 16px;
    height: 16px;

    transform: translateY(-50%);

    border-radius: 3px;
    background-color: var(--main-clr);
}

.serv-tbl__toggle::before,
.serv-tbl__toggle::after
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: 8px;
    height: 2px;
    margin-top: -1px;
    margin-left: -4px;

    content: '';

    background-color: var(--white-clr);
}

.serv-tbl__toggle::after
{
    transform: rotate(90deg);
}

.serv-tbl__delete
{
    transition: .4s;

    opacity: 0;
}

.serv-tbl__item
{
    border-top: 1px solid var(--bg-input-focus-clr);
}

.serv-tbl__item--btn
{
    padding: 12px;
}

.serv-tbl__item[open] summary
{
    background-color: var(--bg-input-clr);
}

.serv-tbl__item[open] summary .serv-tbl__toggle
{
    background-color: var(--gray-clr);
}

.serv-tbl__item[open] summary .serv-tbl__toggle::after
{
    display: none;
}

.serv-tbl__sublist
{
    margin-left: 48px;
}

.serv-tbl__subitem
{
    position: relative;

    padding: 12px 12px 12px 24px;
}

.serv-tbl__subitem:not(:last-child)
{
    border-bottom: 1px solid var(--bg-input-focus-clr);
}

.serv-tbl__subitem::before
{
    position: absolute;
    top: 16px;
    left: 0;

    width: 10px;
    height: 10px;
    margin-top: -2px;

    content: '';

    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: var(--gray-clr);
    border-bottom-left-radius: 3px;
}

.serv-tbl__subitem--add::before
{
    top: 24px;
}

.serv-tbl__subitem--btn::before
{
    display: none;
}

.serv-tbl__subtitle
{
    font-weight: 500;
    line-height: 1.6;

    margin-bottom: 4px;
}

.serv-tbl__description
{
    line-height: 1.4;

    color: var(--gray-clr);
}

.serv-tbl__time
{
    line-height: 1.6;
}

.members
{
    display: flex;
}

.members__item
{
    flex: 0 0 auto;
}

.member
{
    position: relative;

    overflow: hidden;

    width: 24px;
    height: 24px;

    transition: box-shadow .24s;

    opacity: var(--members-opacity, 1);
    border-radius: 50%;
    box-shadow: 0 0 0 4px var(--bg-clr);
}

.member img
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    -o-object-fit: cover;
    object-fit: cover;
}

.files
{
    margin-top: 8px;
}

.files .file-item
{
    margin-top: 4px;
}

.file-item
{
    font-weight: 500;

    position: relative;
}

.file-item:hover .file-item__extra,
.file-item.has-error .file-item__extra
{
    opacity: 1;
}

.file-item:hover
{
    --background-color: var(--bg-input-clr);
}

.file-item.has-error
{
    --background-color: var(--bg-input-error-clr);
}

.file-item.in-progress
{
    --color-name: var(--gray-clr);
    --opacity-cover: .5;
    --opacity-extra: 1;
}

.file-item__main
{
    overflow: hidden;

    transition: color .24s;
    text-decoration: none;

    color: var(--text-clr);
    border-radius: 6px;
    background-color: var(--background-color, transparent);
}

.file-item__main--main
{
    display: flex;

    padding: var(--gap-2xs) var(--gap-xs) var(--gap-2xs) 0;

    gap: var(--gap-xs);
    align-items: center;
}

.file-item__main--preview
{
    display: grid;

    padding-bottom: var(--gap-2xs);

    border: 1px solid var(--border-file-preview);

    grid-template-columns: var(--icon-lg) 1fr;
    -moz-column-gap: var(--gap-xs);
    column-gap: var(--gap-xs);
    row-gap: var(--gap-2xs);
}

.file-item__preview
{
    border-bottom: 1px solid var(--border-file-preview);
    background-color: var(--bg-input-clr);

    grid-column: 1/3;
}

.file-item__preview .file-preview
{
    display: flex;
    overflow: hidden;

    max-height: 420px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    justify-content: center;
    align-items: center;
}

.file-item__preview .file-preview img
{
    width: 100%;
    max-height: 100%;

    opacity: 0;
}

.file-item__cover
{
    opacity: var(--opacity-cover, 1);

    flex-shrink: 0;
}

.file-item__cover--preview
{
    position: relative;

    width: 32px;
    height: 32px;
    margin-left: var(--gap-xs);

    transition: color .24s;

    border: 1px solid var(--bg-border-switch-clr);
    background-color: var(--bg-clr);
}

.file-item__cover--preview img
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    -o-object-fit: contain;
    object-fit: contain;
}

.file-item__info
{
    overflow: hidden;

    margin-right: calc(var(--icon-lg) * 2);
}

.file-item__name
{
    font-size: var(--font-size-sm);
    line-height: 1;

    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;

    color: var(--color-name, var(--text-clr));
}

.file-item__description
{
    font-size: var(--font-size-xs);
    line-height: 1;

    margin-top: var(--gap-2xs);

    transition: color .24s;
    white-space: nowrap;

    color: var(--gray-clr);
}

.file-item__description strong
{
    font-weight: 500;
}

.file-item__extra
{
    position: absolute;
    z-index: 2;
    right: var(--gap-xs);
    bottom: var(--gap-2xs);

    display: grid;

    height: var(--icon-lg);

    opacity: var(--opacity-extra, 0);

    grid-auto-flow: column;
    align-items: center;
    gap: var(--gap-2xs);
}

.file-item__btn
{
    padding: 4px;

    cursor: pointer;

    color: var(--gray-clr);
    border: 0 none;
    background-color: transparent;
}

.file-item__btn:hover
{
    color: var(--gray-hover-clr);
}

.k-board
{
    display: flex;
    flex-direction: column;

    height: 100%;
}

.k-board__wrap
{
    overflow-x: auto;

    flex-grow: 1;
}

.k-board__row
{
    display: flex;

    min-height: 100%;

    gap: var(--indent-md);
}

.k-board__col
{
    max-width: 280px;
    padding: 16px;

    transition: background-color .24s;

    border-radius: var(--radius-md);
    background-color: var(--bg-input-clr);

    flex: 1 0 260px;
}

.k-board-col__top
{
    display: flex;

    margin-bottom: 24px;

    flex-wrap: wrap;
    align-items: baseline;
    gap: 8px;
}

.k-board-col__title
{
    font-weight: 600;
}

.k-board-col__qty
{
    font-size: 12px;

    transition: color .24s;

    color: var(--label-clr);
}

.k-board-card
{
    padding: 16px;

    cursor: pointer;
    transition: background-color .24s, border-color .24s;

    border: 1px solid var(--border-page-clr);
    border-radius: 6px;
    background-color: var(--bg-clr);
    box-shadow: 0 4px 16px rgba(60, 65, 77, .05);
}

.k-board-card--bordered
{
    border: 1px solid var(--border-page-clr);
    box-shadow: none;
}

.k-board-card:not(:last-child)
{
    margin-bottom: 16px;
}

.k-board-card__top
{
    display: flex;

    margin-bottom: 8px;

    align-items: center;
    gap: 8px;
}

.k-board-card__number
{
    font-size: 12px;
    font-weight: 600;

    margin-right: auto;

    transition: color .24s;

    color: var(--gray-clr);
}

.k-board-card__title
{
    font-weight: 500;

    display: flex;

    margin-bottom: 8px;

    align-items: center;
    -moz-column-gap: 8px;
    column-gap: 8px;
}

.k-board-card__until
{
    font-size: 12px;

    margin-bottom: 4px;

    transition: color .24s;

    color: var(--gray-clr);
}

.k-board-card__project
{
    font-weight: 600;
}

.pu-wrap
{
    position: fixed;
    z-index: 64;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    transition: opacity .4s;
    pointer-events: none;

    opacity: 0;
}

.pu-wrap.is-open
{
    pointer-events: auto;

    opacity: 1;
}

.pu-box
{
    display: none;

    width: 100%;
    max-height: 100%;

    background-color: var(--bg-popup-clr);
    box-shadow: 0 12px 32px rgba(0, 0, 0, .1);

    --v-gap-min: var(--gap-sm);
    --v-gap-max: var(--gap-lg);
    --h-gap-max: var(--gap-2xl);
}

.pu-box--right
{
    margin-left: auto;

    align-self: stretch;
}

.pu-box--center
{
    margin: auto;

    border-radius: var(--radius-xl);
}

.pu-box--lg
{
    max-width: 700px;
}

.pu-box--md
{
    max-width: 520px;
}

.pu-box--sm
{
    max-width: 380px;
}

.pu-box.is-open
{
    display: flex;
    flex-direction: column;
}

.pu-box__wrap
{
    position: relative;

    display: flex;
    overflow: hidden;
    flex-direction: column;

    flex-grow: 1;
}

.pu-box__top,
.pu-box__main,
.pu-box__bottom
{
    padding: var(--v-gap-max) var(--h-gap-max);
}

.pu-box__under-top
{
    padding: var(--v-gap-min) var(--h-gap-max);
}

.pu-box__top
{
    border-bottom: 1px solid var(--border-page-clr);
}

.pu-box__top--center
{
    display: grid;

    grid-template-columns: 1fr var(--control-height-md);
    gap: var(--gap-md);
    align-items: center;
}

.pu-box__title
{
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-medium);
}

.pu-box__main
{
    position: relative;

    display: flex;
    overflow: auto;
    overflow-y: auto;
    flex-direction: column;

    transition: var(--duration-sm);

    flex-grow: 1;
    gap: var(--gap-2xl);
    scroll-behavior: smooth;
    --scrollbar-size-base: var(--gap-xs);
    --scrollbar-color: var(--bg-input-focus-clr);
    --scrollbar-color-hover: var(--bg-input-focus-clr);
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-color) transparent;
}

.pu-box__main::-webkit-scrollbar
{
    width: var(--scrollbar-size-base);
}

.pu-box__main::-webkit-scrollbar-track
{
    background-color: transparent;
}

.pu-box__main::-webkit-scrollbar-thumb
{
    -webkit-transition: var(--duration-sm);
    transition: var(--duration-sm);

    border-radius: calc(var(--scrollbar-size-base) / 2);
    background-color: var(--scrollbar-color);
}

.pu-box__main::-webkit-scrollbar-thumb:hover
{
    background-color: var(--scrollbar-color-hover);
}

.pu-box__no-access
{
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    justify-content: center;
}

.pu-box__body
{
    flex-grow: 1;
}

.pu-box__block:not(:last-child)
{
    margin-bottom: var(--gap-lg);
}

.pu-box__block--border-top
{
    padding-top: var(--gap-lg);

    border-top: 1px solid var(--border-page-clr);
}

.pu-box__bottom
{
    border-top: 1px solid var(--border-page-clr);
}

.pu-box__form-top
{
    margin-bottom: var(--gap-xs);

    color: var(--gray-clr);
}

.pu-box__form-comment
{
    position: relative;
}

.pu-box__form-files
{
    margin-top: var(--gap-md);
    margin-left: var(--gap-3xl);
}

.pu-box__btns
{
    display: flex;

    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
}

.pu-box__btns--right
{
    justify-content: flex-end;
}

.drop-zone
{
    position: absolute;
    z-index: 16;
    top: 96px;
    right: var(--gap-xl);
    bottom: var(--gap-xl);
    left: var(--gap-2xl);

    display: flex;

    transition: var(--duration-sm);
    pointer-events: none;

    opacity: 0;

    align-items: center;
    justify-content: center;
}

.drop-zone.drag-over
{
    overflow: hidden;

    opacity: 1;
    border: 1px dashed hsl(var(--blue-clr-src)/40%);
    border-radius: var(--radius-lg);
    background-color: hsl(var(--blue-clr-src)/10%);

    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
}

.drop-zone__message
{
    display: flex;
    flex-direction: column;

    cursor: default;

    align-items: center;
    gap: var(--gap-xs);
}

.drop-zone__message-icon,
.drop-zone__message-title
{
    font-size: var(--font-size-lg);

    color: var(--drop-zone-clr);
}

.drop-zone__message-text
{
    font-size: var(--font-size-sm);

    text-align: center;
}

.task-related
{
    display: flex;
    flex-direction: column;

    gap: var(--gap-sm);
}

.task-related__main
{
    display: flex;
    flex-direction: column;

    margin-top: var(--gap-xs);

    gap: var(--gap-md);
}

.task-related__list
{
    display: flex;
    flex-direction: column;

    padding: var(--gap-lg);

    border-radius: var(--radius-lg);
    background-color: var(--border-page-clr);

    gap: var(--gap-sm);
}

.more-item[open]
{
    --marker-transform: rotate(180deg);
}

.more-item--xs
{
    --font-size: var(--font-size-xs);
    --line-height: var(--icon-sm);
}

.more-item--gray
{
    --color-title: var(--label-clr);
}

.more-item__top
{
    display: flex;

    list-style: none;

    gap: var(--gap-xs);
}

.more-item__top::-webkit-details-marker
{
    display: none;
}

.more-item__title
{
    font-size: var(--font-size);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height);

    color: var(--color-title);

    flex-grow: 1;
}

.more-item__marker
{
    transform: var(--marker-transform);

    color: var(--gray-clr);
}

.more-item__main
{
    margin-top: var(--gap-sm);
}

.t-editor
{
    border: 1px solid var(--border-page-clr);
    border-radius: 6px;
}

.t-editor__top
{
    position: sticky;
    z-index: 2;
    top: 0;

    padding: 16px;

    border-bottom: 1px solid var(--border-page-clr);
    background-color: var(--bg-clr);
}

.t-editor__ctrls
{
    display: flex;

    flex-wrap: wrap;
    gap: 8px;
}

.t-editor__main
{
    padding-top: 8px;
    padding-bottom: 8px;
}

.t-editor__main .article
{
    padding: 0 12px;
}

.t-editor__main .article:focus
{
    outline: none;
}

.t-editor__body
{
    padding-right: 16px;
    padding-left: 16px;
}

.t-select
{
    position: relative;

    min-width: 200px;
}

.t-select__current,
.t-select__btn
{
    position: relative;

    display: block;

    width: 100%;
    padding-right: 24px;

    cursor: pointer;
    text-align: left;

    border: 0 none;
}

.t-select__current::after,
.t-select__btn::after
{
    position: absolute;
    top: 50%;
    right: 8px;

    content: '';
    transform: translateY(-50%);

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.t-select__current
{
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;

    border-radius: 6px;
    background-color: var(--bg-input-clr);
}

.t-select__current::after
{
    width: 12px;
    height: 12px;

    background-image: url('../img/icon-select-light.svg');
}

.t-select__btn
{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;

    background-color: transparent;
}

.t-select__btn.is-active
{
    background-color: var(--bg-select-box-hover-clr);
}

.t-select__btn.is-active::after
{
    width: 16px;
    height: 16px;

    opacity: .6;
    background-image: url('../img/sprite.svg#check-16');
}

.t-select__btn:hover
{
    background-color: var(--bg-select-box-hover-clr);
}

.t-select__label
{
    line-height: 24px;
}

.t-select__label--title,
.t-select__label--subtitle
{
    font-weight: 600;
}

.t-select__label--title
{
    font-size: 18px;
}

.t-select__label--subtitle
{
    font-size: 16px;
}

.t-select__list
{
    position: absolute;
    top: 100%;
    left: 0;

    display: none;

    width: 100%;
    margin: 0;
    margin-top: 1px;
    padding: 8px 0;

    list-style: none;

    border-radius: 6px;
    background-color: var(--bg-clr);
    box-shadow: 0 12px 32px rgba(0, 0, 0, .1);
}

.t-select__list.is-open
{
    display: block;
}

.t-btn
{
    padding: 4px;

    cursor: pointer;

    color: var(--gray-clr);
    border: 0 none;
    border-radius: 6px;
    background-color: transparent;
}

.t-btn:hover,
.t-btn.is-active
{
    color: var(--label-clr);
    background-color: var(--bg-input-clr);
}

.t-btn.is-disabled
{
    pointer-events: none;

    color: var(--gray-light-clr);
}

.task-teas
{
    display: grid;

    grid-template-columns: 100%;
    gap: var(--indent-xl);
}

.task-teas__title
{
    font-size: var(--font-size-xl);
    font-weight: bold;
    line-height: normal;
}

.task-teas__body
{
    display: grid;

    grid-template-columns: 100%;
    gap: var(--indent-xl);
}

.task-deleting
{
    animation: toRight .8s;
}

@keyframes toRight
{
    from
    {
        left: 0;

        opacity: 1;
    }

    to
    {
        left: 100%;

        opacity: 0;
    }
}

.task-item
{
    display: flex;

    align-items: center;
    gap: var(--indent-md);
}

.task-item--done
{
    --line-width: 100%;
    --name-color: var(--gray-hover-clr);
}

.task-item__check
{
    cursor: pointer;
}

.task-item__check input
{
    position: absolute;

    overflow: hidden;
    clip: rect(0 0 0 0);

    width: 1px;
    height: 1px;
    margin: -1px;

    border: 0;

    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
}

.task-item__check input:checked + span
{
    color: var(--clr-white);
    border-color: var(--success-clr);
    background-color: var(--success-clr);
}

.task-item__check span
{
    display: flex;

    width: var(--icon-md);
    height: var(--icon-md);

    transition: .32s;

    color: var(--bg-clr);
    border: 1px solid var(--bg-tswitch-clr);
    border-radius: 50%;
    background-color: var(--bg-clr);

    align-items: center;
    justify-content: center;
}

.task-item__extra
{
    flex-shrink: 0;
}

.task-item__main
{
    display: flex;

    width: 100%;
    padding: var(--gap-md) 0;

    text-align: left;

    flex-grow: 1;
}

.task-item__info
{
    font-weight: 500;

    display: flex;

    min-height: var(--icon-md);

    color: var(--name-color);

    flex-grow: 1;
    align-items: center;
    gap: var(--indent-xs);
}

.task-item__time
{
    font-size: 12px;
    line-height: var(--indent-md);

    padding: 4px 8px;

    border-radius: var(--radius-md);
    background-color: var(--bg-input-clr);
}

.task-item__priority--high
{
    color: var(--bg-table-icon-urgent);
}

.task-item__name
{
    font-size: 14px;

    position: relative;

    transition: .32s;
}

.task-item__name::before
{
    position: absolute;
    top: 50%;
    left: 0;

    width: var(--line-width, 0);
    height: 1px;

    content: '';
    transition: width .32s;

    background-color: var(--gray-hover-clr);
}

.task-item__name--new
{
    font-weight: bold;
}

.task-item__project
{
    font-size: 12px;
}

.person-item
{
    display: inline-flex;

    padding: 0 var(--indent-md) 0 0;

    transition: .24s;
    vertical-align: middle;

    border-radius: calc(var(--cover-size) / 2);
    background-color: var(--bg-color);

    --cover-size: var(--icon-size-lg);
    --bg-color: var(--bg-input-clr);
    gap: var(--indent-sm);
    align-items: center;
}

button.person-item:hover
{
    background-color: var(--bg-input-focus-clr);
}

button.person-item:hover .person-item__photo
{
    transform: scale(1.2);
}

button.person-item.is-active
{
    --border-cover-color: var(--blue-clr);
}

button.person-item.is-active:hover
{
    color: var(--white-clr);
    background-color: var(--blue-clr);
}

.person-item.is-active
{
    color: var(--white-clr);
    background-color: var(--blue-clr);
}

.person-item__photo
{
    position: relative;

    overflow: hidden;

    box-sizing: border-box;
    width: var(--cover-size);
    height: var(--cover-size);
    margin-left: -2px;

    transition: .24s;

    border: 2px solid var(--border-cover-color, var(--bg-color));
    border-radius: 50%;
    background-color: var(--bg-color);

    flex-shrink: 1;
}

.person-item__photo img
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    -o-object-fit: cover;
    object-fit: cover;
}

.person-item__name
{
    font-size: var(--font-size-md);
    line-height: 1.5;
}

.project-item
{
    position: relative;

    display: flex;

    width: -moz-max-content;
    width:      max-content;
    max-width: 100%;
    padding: 0 var(--indent-md) 0 0;

    transition: .24s;

    border-radius: var(--radius-md);
    background-color: var(--bg-color);

    --cover-size: var(--icon-size-lg);
    --cover-bg-color: var(--bg-input-clr);
    --bg-color: var(--bg-input-clr);
    gap: var(--indent-xs);
    align-items: center;
}

.project-item--pure
{
    --bg-color: transparent;
    --cover-size: var(--icon-size-md);
    --font-size: var(--font-size-xs);
    --color-name: var(--gray-clr);
}

button.project-item:hover
{
    --bg-color: var(--bg-input-focus-clr);
}

button.project-item.is-active
{
    --cover-bg-color: var(--bg-input-clr-light);
}

button.project-item.is-active:hover
{
    color: var(--white-clr);
    background-color: var(--blue-clr);
}

.project-item.is-active
{
    color: var(--white-clr);
    background-color: var(--blue-clr);

    --border-cover-color: var(--blue-clr);
}

.project-item__cover
{
    font-size: 20px;
    line-height: 1;

    position: relative;

    display: flex;

    box-sizing: border-box;
    width: var(--cover-size);
    height: var(--cover-size);

    transition: .24s;

    border: 2px solid var(--border-cover-color, var(--bg-color));
    border-radius: var(--radius-md);
    background-color: var(--bg-color);

    flex-basis: var(--cover-size);
    align-items: center;
    justify-content: center;
}

.project-item__cover .svg
{
    fill: var(--gray-clr);
}

.project-item__name
{
    font-size: var(--font-size, var(--font-size-md));
    line-height: 1.5;

    position: relative;

    overflow: hidden;

    white-space: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: var(--color-name);
}

.priority-item
{
    display: flex;

    padding: 4px var(--indent-md) 4px var(--indent-xs);

    transition: .24s;

    border-radius: var(--radius-md);
    background-color: var(--bg-input-clr);

    gap: var(--indent-xs);
    align-items: center;
}

.priority-item--normal
{
    --icon-clr: var(--blue-light-clr);
}

.priority-item--hight
{
    --icon-clr: var(--urgent-clr);
}

.priority-item.is-active
{
    color: var(--white-clr);
    background-color: var(--blue-clr);
}

.priority-item__cover
{
    flex-shrink: 1;
}

.priority-item__cover .svg
{
    fill: var(--icon-clr);
}

.priority-item__name
{
    font-size: var(--font-size-md);
    line-height: 1.5;
}

.seg-nav--s
{
    --height: var(--control-height-sm);
    --font-size: .75em;
}

.seg-nav--m
{
    --height: var(--control-height-md);
    --font-size: 1em;
}

.seg-nav--wide
{
    --item-flex-grow: 1;
}

.seg-nav__list
{
    display: flex;

    margin: 0;
    padding: 0;

    list-style: none;

    align-items: center;
    flex-wrap: wrap;
}

.seg-nav__item
{
    flex-grow: var(--item-flex-grow, 0);
}

.seg-nav__item:not(:first-child) .seg-nav__value
{
    border-width: 1px 1px 1px 0;
}

.seg-nav__item:last-child .seg-nav__value
{
    border-top-right-radius: var(--radius-base);
    border-bottom-right-radius: var(--radius-base);
}

.seg-nav__item:first-child .seg-nav__value
{
    border-width: 1px;
    border-top-left-radius: var(--radius-base);
    border-bottom-left-radius: var(--radius-base);
}

.seg-nav__switch
{
    position: relative;

    display: block;
}

.seg-nav__switch input
{
    position: absolute;

    overflow: hidden;
    clip: rect(0 0 0 0);

    width: 1px;
    height: 1px;
    margin: -1px;

    border: 0;

    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
}

.seg-nav__switch input:checked + .seg-nav__value
{
    color: var(--white-clr);
    background-color: var(--blue-clr);
}

.seg-nav__switch input:disabled + .seg-nav__value
{
    color: var(--tab-disabled-clr);
}

.seg-nav__switch input:disabled + .seg-nav__value:hover
{
    background-color: transparent;
}

.seg-nav__value
{
    font-size: var(--font-size);
    font-weight: 500;
    line-height: calc(var(--height) - 2px);

    display: block;

    padding: 0 var(--indent-lg);

    cursor: pointer;
    transition: background-color .24s;
    text-decoration: none;

    color: var(--blue-clr);
    border-style: solid;
    border-color: var(--blue-clr);
}

.seg-nav__value:hover
{
    background-color: hsl(var(--clr-primary-h) var(--clr-primary-s) var(--clr-primary-l)/.08);
}

.spinner
{
    display: inline-flex;

    vertical-align: middle;

    align-items: center;
    justify-content: center;
}

.spinner .svg-clr
{
    height: auto;

    animation: 1s linear 0s normal none infinite running rotation;
}

@keyframes rotation
{
    0%
    {
        transform: rotate(0deg);
    }

    100%
    {
        transform: rotate(360deg);
    }
}

.loader
{
    display: inline-flex;

    vertical-align: middle;

    gap: calc(var(--dot-size) / 2);
}

.loader--m
{
    --dot-size: 16px;
}

.loader--s
{
    --dot-size: 12px;
}

.loader span
{
    position: relative;

    display: block;

    width: var(--dot-size);
    height: var(--dot-size);

    animation: dots 1.2s infinite ease;

    border-radius: 50%;
    background-color: var(--clr-primary);
}

.loader span:nth-child(2)
{
    animation-delay: .2s;
}

.loader span:nth-child(3)
{
    animation-delay: .4s;
}

@keyframes dots
{
    0%,
    100%
    {
        background-color: var(--clr-primary-light);
    }

    50%
    {
        background-color: var(--clr-primary);
    }
}

.preloader
{
    display: none;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.preloader--page
{
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    height: 100vh;

    background-color: var(--bg-preloader-page-clr);

    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.preloader.is-visible
{
    z-index: 200;

    display: flex;
}

.preloader__spinner
{
    width: calc(var(--icon-xl) * 2);
    height: calc(var(--icon-xl) * 2);
}

.preloader__spinner svg
{
    width: 100%;
    height: 100%;
}

.blank-page
{
    display: flex;

    width: 100vw;
    height: 100dvh;

    align-items: center;
    justify-content: center;
}

.blank-page__box
{
    text-align: center;
}

.blank-page__cover
{
    max-width: -moz-max-content;
    max-width:      max-content;
    margin: 0 auto;
}

.blank-page__title
{
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-medium);

    margin: 1em 0;
}

.blank-page__text
{
    font-size: var(--font-size-sm);

    color: var(--gray-hover-clr);
}

:root
{
    --ss-bg-color: #fff;
    --ss-font-color: var(--bg-input-clr);
    --ss-font-placeholder-color: var(--placeholder-clr);
    --ss-disabled-color: #dcdee2;
    --ss-border-color: #dcdee2;
    --ss-highlight-color: #fffb8c;
    --ss-success-color: #00b755;
    --ss-error-color: #dc3545;
    --ss-content-height: 320px;
    --ss-spacing-l: var(--indent-sm);
    --ss-spacing-m: var(--indent-xs);
    --ss-spacing-s: calc(var(--indent-xs) / 2);
    --ss-animation-timing: .24s;
}

@keyframes ss-valueIn
{
    0%
    {
        transform: scale(0);

        opacity: 0;
    }

    100%
    {
        transform: scale(1);

        opacity: 1;
    }
}

@keyframes ss-valueOut
{
    0%
    {
        transform: scale(1);

        opacity: 1;
    }

    100%
    {
        transform: scale(0);

        opacity: 0;
    }
}

.ss-hide
{
    display: none !important;
}

.ss-main
{
    position: relative;

    display: flex;
    overflow: hidden;
    flex-direction: row;

    box-sizing: border-box;
    width: 100%;
    min-height: var(--control-height);
    margin: 0 calc(var(--gap-md) * -1);
    padding: 0 var(--gap-md);

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition: background-color var(--ss-animation-timing);

    color: var(--text-color);
    border: none;
    border-radius: var(--radius-base);
    outline: 0;
}

.ss-main.ss-disabled
{
    cursor: not-allowed;

    background-color: var(--ss-disabled-color);
}

.ss-main.ss-disabled .ss-values .ss-disabled
{
    color: var(--ss-font-color);
}

.ss-main.ss-disabled .ss-values .ss-value .ss-value-delete
{
    cursor: not-allowed;
}

.ss-main.ss-open-above
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.ss-main.ss-open-below
{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.ss-main .ss-values
{
    display: inline-flex;

    padding: calc(var(--indent-xs) / 2) 0;

    align-items: center;
    flex-wrap: wrap;
    gap: var(--ss-spacing-m);
    flex: 1 1 100%;
}

.ss-main .ss-values .ss-placeholder
{
    line-height: 1em;

    display: flex;
    overflow: hidden;

    width: 100%;
    margin: auto 0 auto 0;

    transition: color .24s;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: var(--placeholder-clr);

    align-items: center;
}

.ss-main .ss-values .ss-max
{
    font-size: 12px;
    line-height: 1;

    display: flex;

    width: -moz-fit-content;
    width:      fit-content;
    padding: var(--ss-spacing-s) var(--ss-spacing-m);

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    color: var(--ss-bg-color);
    border-radius: var(--radius-base);
    background-color: var(--main-clr);

    align-items: center;
}

.ss-main .ss-values .ss-single
{
    display: flex;

    margin: auto 0 auto;
}

.ss-main .ss-values .ss-value
{
    display: flex;

    width: -moz-fit-content;
    width:      fit-content;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    animation-name: ss-valueIn;
    animation-duration: var(--ss-animation-timing);
    animation-timing-function: ease-out;

    border-radius: var(--radius-base);
    background-color: var(--main-clr);

    align-items: center;
    animation-fill-mode: both;
}

.ss-main .ss-values .ss-value.ss-value-out
{
    animation-name: ss-valueOut;
    animation-duration: var(--ss-animation-timing);
    animation-timing-function: ease-out;
}

.ss-main .ss-values .ss-value .ss-value-text
{
    font-size: 12px;
    line-height: 1;

    padding: var(--ss-spacing-s) var(--ss-spacing-m) var(--ss-spacing-s) 0;

    color: var(--ss-bg-color);
}

.ss-main .ss-values .ss-value .ss-value-delete
{
    display: flex;

    box-sizing: content-box;
    width: var(--ss-spacing-m);
    height: var(--ss-spacing-m);
    margin-right: calc(var(--ss-spacing-m) * -1);
    margin-left: calc(var(--ss-spacing-m) * -1);
    padding: var(--ss-spacing-s) var(--ss-spacing-m);

    cursor: pointer;

    align-items: center;
}

.ss-main .ss-values .ss-value .ss-value-delete svg
{
    width: var(--ss-spacing-l);
    height: var(--ss-spacing-l);
}

.ss-main .ss-values .ss-value .ss-value-delete svg path
{
    fill: none;
    stroke: var(--ss-bg-color);
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-main .ss-deselect
{
    display: flex;

    width: 8px;
    height: 8px;
    margin: 0 var(--ss-spacing-m) 0 var(--ss-spacing-m);

    align-self: center;
    justify-content: flex-end;
    flex: 0 1 auto;
}

.ss-main .ss-deselect svg
{
    width: 8px;
    height: 8px;
}

.ss-main .ss-deselect svg path
{
    fill: none;
    stroke: var(--ss-font-color);
    stroke-width: 20;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-main .ss-arrow
{
    display: flex;

    width: 12px;
    height: 12px;
    margin: auto 0 auto var(--ss-spacing-m);

    align-items: center;
    justify-content: flex-end;
    flex: 0 1 auto;
}

.ss-main .ss-arrow path
{
    transition: var(--ss-animation-timing);
    transition-timing-function: ease-out;

    fill: none;
    stroke: var(--placeholder-clr);
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-content
{
    position: absolute;
    z-index: 10000;

    display: flex;
    overflow: hidden;
    flex-direction: column;

    box-sizing: border-box;
    width: auto;
    height: auto;
    max-height: var(--ss-content-height);

    transition: transform var(--ss-animation-timing), opacity var(--ss-animation-timing);
    transform: scaleY(0);
    transform-origin: center top;

    opacity: 0;
    border-radius: var(--radius-base);
    background-color: var(--bg-clr);
    box-shadow: 0 8px 20px hsla(222, 12%, 27%, .08);
}

.ss-content.ss-relative
{
    position: relative;

    height: 100%;
}

.ss-content.ss-open-above
{
    flex-direction: column-reverse;

    transform: scaleY(1);
    transform-origin: center bottom;

    opacity: 1;
}

.ss-content.ss-open-below
{
    transform: scaleY(1);
    transform-origin: center top;

    opacity: 1;
}

.ss-content .ss-search
{
    display: flex;
    flex-direction: row;

    padding: var(--indent-xs) var(--indent-sm);

    flex: 0 1 auto;
}

.ss-content .ss-search input
{
    font-size: inherit;
    line-height: inherit;

    display: inline-flex;

    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: var(--indent-xs) var(--indent-sm);

    text-align: left;

    color: var(--text-clr);
    border-radius: var(--radius-base);
    outline: 0;
    background-color: var(--bg-input-focus-clr);

    flex: 1 1 auto;
}

.ss-content .ss-search input::-moz-placeholder
{
    vertical-align: middle;

    color: var(--placeholder-clr);
}

.ss-content .ss-search input::placeholder
{
    vertical-align: middle;

    color: var(--placeholder-clr);
}

.ss-content .ss-search input:focus
{
    box-shadow: 0 0 5px var(--main-clr);
}

.ss-content .ss-search .ss-addable
{
    display: inline-flex;

    height: auto;
    margin: 0 0 0 var(--ss-spacing-m);

    cursor: pointer;

    border: 1px solid var(--ss-border-color);
    border-radius: var(--radius-base);

    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
}

.ss-content .ss-search .ss-addable svg
{
    display: flex;

    width: 12px;
    height: 12px;
    margin: auto var(--ss-spacing-m) auto var(--ss-spacing-m);

    align-items: center;
    justify-content: flex-end;
    flex: 0 1 auto;
}

.ss-content .ss-search .ss-addable svg path
{
    fill: none;
    stroke: var(--ss-font-color);
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-content .ss-list
{
    overflow-y: auto;

    height: auto;

    transition: var(--duration-sm);

    flex: 1 1 auto;
    --scrollbar-size-base: var(--gap-xs);
    --scrollbar-color: var(--bg-input-focus-clr);
    --scrollbar-color-hover: var(--bg-input-focus-clr);
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-color) transparent;
}

.ss-content .ss-list::-webkit-scrollbar
{
    width: var(--scrollbar-size-base);
}

.ss-content .ss-list::-webkit-scrollbar-track
{
    background-color: transparent;
}

.ss-content .ss-list::-webkit-scrollbar-thumb
{
    -webkit-transition: var(--duration-sm);
    transition: var(--duration-sm);

    border-radius: calc(var(--scrollbar-size-base) / 2);
    background-color: var(--scrollbar-color);
}

.ss-content .ss-list::-webkit-scrollbar-thumb:hover
{
    background-color: var(--scrollbar-color-hover);
}

.ss-content .ss-list .ss-error
{
    padding: var(--ss-spacing-l);

    color: var(--ss-error-color);
}

.ss-content .ss-list .ss-searching
{
    padding: var(--ss-spacing-l);

    color: var(--ss-font-color);
}

.ss-content .ss-list .ss-optgroup.ss-close .ss-option
{
    display: none !important;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label
{
    display: flex;
    flex-direction: row;

    padding: var(--ss-spacing-m) var(--ss-spacing-l) var(--ss-spacing-m) var(--ss-spacing-l);

    align-items: center;
    justify-content: space-between;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-label-text
{
    font-weight: bold;

    color: var(--ss-font-color);

    flex: 1 1 auto;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label:has(.ss-arrow)
{
    cursor: pointer;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions
{
    display: flex;
    flex-direction: row;

    flex: 0 1 auto;
    align-items: center;
    justify-content: center;
    gap: var(--ss-spacing-m);
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall
{
    display: flex;
    flex-direction: row;

    cursor: pointer;

    flex: 0 0 auto;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall:hover
{
    opacity: .5;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall.ss-selected svg path
{
    stroke: var(--ss-error-color);
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall span
{
    font-size: 60%;

    display: flex;

    padding: 0 var(--ss-spacing-s) 0 0;

    text-align: center;

    flex: 0 1 auto;
    align-items: center;
    justify-content: center;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg
{
    width: 13px;
    height: 13px;

    flex: 0 1 auto;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg path
{
    fill: none;
    stroke: var(--ss-success-color);
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg:first-child
{
    stroke-width: 5;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg:last-child
{
    stroke-width: 11;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-closable
{
    display: flex;
    flex-direction: row;

    cursor: pointer;

    flex: 0 1 auto;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-closable .ss-arrow
{
    width: 10px;
    height: 10px;

    flex: 1 1 auto;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-closable .ss-arrow path
{
    transition: var(--ss-animation-timing);
    transition-timing-function: ease-out;

    fill: none;
    stroke: var(--ss-font-color);
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-content .ss-list .ss-optgroup .ss-option
{
    padding: var(--ss-spacing-s) var(--ss-spacing-s) var(--ss-spacing-s) calc(var(--ss-spacing-l) * 3);
}

.ss-content .ss-list .ss-option
{
    display: flex;

    padding: var(--ss-spacing-m) var(--ss-spacing-l) var(--ss-spacing-m) var(--ss-spacing-l);

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition: var(--duration-sm);

    color: var(--text-clr);
    background-color: var(--bg-clr);
}

.ss-content .ss-list .ss-option:hover
{
    background-color: var(--bg-input-clr);
}

.ss-content .ss-list .ss-option.ss-highlighted,
.ss-content .ss-list .ss-option:not(.ss-disabled).ss-selected
{
    color: var(--gray-clr);
    background-color: var(--bg-input-focus-clr);
}

.ss-content .ss-list .ss-option.ss-disabled
{
    cursor: not-allowed;

    background-color: var(--ss-disabled-color);
}

.ss-content .ss-list .ss-option.ss-disabled:hover
{
    color: var(--ss-font-color);
}

.ss-content .ss-list .ss-option .ss-search-highlight
{
    background-color: var(--ss-highlight-color);
}
